import React from "react";
import dayjs from "../utils/dayjs";
import numeral from "../utils/numeral";
import PeriodDifference from "./PeriodDifference";
import ChartData from "../types/ChartData";

const ChartFlyout: React.FC<{
    x?
    y?
    datum?
    format
    dateTickFormat
    dateGroupBy
    data: ChartData
}> = ({
 x,
 y,
 datum ,
 format,
 dateGroupBy,
 dateTickFormat,
 data}) => {

        let previousDate = dayjs.utc(datum.x).subtract(30, 'days')
        let prepend = '30 days ago'
        if(dateGroupBy === 'month') {
            previousDate = dayjs.utc(datum.x).subtract(1, 'month')
            prepend = '1 month ago'
        }  else if(dateGroupBy === 'week') {
            previousDate = dayjs.utc(datum.x).subtract(1, 'week')
            prepend = 'previous week'
        } else if(dateGroupBy === 'year') {
            previousDate = dayjs.utc(datum.x).subtract(1, 'year')
            prepend = '1 year ago'
        }


        const previousItem = data.dataPoints.filter(item => dayjs.utc(item.x).isSame(previousDate))[0]
        return <g style={{ pointerEvents: "none" }}>
                <foreignObject
                    x={x - 150}
                    y={y - 119}
                    width="150"
                    height="10"
                    style={{ overflow: "visible" }}
                >
                    <div style={{
                        padding:12,
                        textAlign:'center',
                        background:'#fff',
                        boxShadow:'0 1px 2px rgba(96,120,255,.1)',
                        border: '1px solid #f5f5f7',

                    }}>
                        <div
                            style={{
                                fontSize: 10,
                                marginBottom:4,
                                fontWeight:600,
                                color: '#444',
                            }}
                        >{
                            dateGroupBy === 'week'
                                ? <div>{dayjs(datum.x).subtract(6, 'days').format(dateTickFormat)} - {dayjs(datum.x).format(dateTickFormat)}</div>
                                : dayjs(datum.x).format(dateTickFormat)

                        }</div>
                        <div
                            style={{
                                fontSize:18,
                                fontWeight: 800,
                                color: '#5333ed'
                            }}
                        >{numeral(datum.y).format(format)}</div>
                        <div style={{ fontSize: 11, marginTop:8}}><PeriodDifference
                            lineBreak={true}
                            prepend={prepend}
                            first={previousItem?.y || 0}
                            last={datum.y}
                        /></div>

                    </div>

                    <div
                        style={{
                            position:'relative',
                            left:144,
                            top:15,
                            width:12,
                            height:12,
                            borderRadius: 24,
                            background: '#fff',
                            border: '2px solid #5333ed'
                        }}
                    ></div>
                </foreignObject>
            </g>
}

export default ChartFlyout