import React from "react";
import {Collapse, Table, Typography} from "antd";
import {BookTwoTone, CalculatorTwoTone, DatabaseTwoTone, InfoCircleTwoTone} from "@ant-design/icons";
import { BlockMath } from 'react-katex';
import {Link} from "react-router-dom";

const DocsNetRevenue: React.FC = (props) => {
    const { Text, Paragraph, Title } = Typography;


    return <div>


        <Paragraph>
            <Title level={5} style={{marginTop:0}}>
                <CalculatorTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Formula
            </Title>
        </Paragraph>

        <BlockMath math="\footnotesize \text{For applicable balance transactions on a given day}"/>
        <BlockMath math="\large
        \sum_{} net \; amount "/>
        <Paragraph>
            <Title level={5}>
                <DatabaseTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Data Source
            </Title>
        </Paragraph>

        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="balanceTransactions" key="dependency">
                <ul>
                    <li>Stripe Balance Transactions
                        <ul>
                            <li><Text code>balanceTransactions</Text></li>
                        </ul>
                    </li>
                </ul>
            </Collapse.Panel>
        </Collapse>



        <Paragraph>
            <Title level={5}>
                <InfoCircleTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Details
            </Title>
        </Paragraph>

        <Paragraph>
            Any event that affects our Stripe balance (an incoming payment from a customer, a payout to our bank account,
            a refund, a dispute, etc) incurs a balance transaction. To calculate net revenue we need to sum the net amounts
            of items where the impact of the balance transaction changes our balance (as opposed to reallocating like
            a payout). The types we take into account are:
        </Paragraph>

        <Paragraph>
            <ul>
                <li>charge</li>
                <li>payment</li>
                <li>payment_failure_refund</li>
                <li>payment_refund</li>
                <li>refund</li>
                <li>refund_failure</li>
                <li>adjustment</li>
                <li>stripe_fee</li>
                <li>stripe_fx_fee</li>
                <li>tax_fee</li>
            </ul>
        </Paragraph>

        <Paragraph>
            <Title level={5}>
                <BookTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Further Reading
            </Title>
        </Paragraph>

        <Paragraph>
        <ul>
            <li><Link to={'https://stripe.com/docs/reports/balance-transaction-types'} target={'_blank'}>Stripe Balance Transaction Types</Link></li>
        </ul>
        </Paragraph>

        </div>

}

export default DocsNetRevenue