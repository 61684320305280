import React, {useState} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import {Button, Select, Table, Tag} from 'antd';
import dayjs, {Dayjs} from "../utils/dayjs";
import Page from '../layouts/Page';
import {useSearchParams} from 'react-router-dom'
import getDateRangeFilterDefaults from "../utils/getDateRangeFilterDefaults";
import PageLoading from '../components/PageLoading';
import formatValue from "../utils/formatValue";
import ValueFormat from "../types/ValueFormats";
import {get, has, set, sum, uniq} from "lodash";
import DateRangePicker from "../components/DateRangePicker";
import {CheckCircleOutlined, CheckOutlined, UndoOutlined} from "@ant-design/icons";

const GET_DATA = gql`
query data(
    $start: Date!
    $end: Date!
) {    
    getBillingReport(start: $start, end: $end) {
        id
        date
        effect
            
        subscription
        customerDescription
        customerCreated
        customerEmail
        quantity
        price
        priceName
        amount
        serviceGroup
        productGroup
        serviceType
        productType
        interval
        mrr
        customerCompany
    }
    
    getBillingReportCompletions(start: $start, end: $end) {
        customer 
        date
        user {
            name
            picture
        }
    }
 
}
`;

const BillingReport: React.FC<{ getSystemProcessing }> = ({ getSystemProcessing }) => {
    /* Get Base Properties */
    const dateCutoff = '2023-04-26'
    const effectMap = {
        new: {
            value: 'new',
            color: 'green',
            label: 'New',
        },
        reactivation: {
            value: 'reactivation',
            color: 'green',
            label: 'Reactivation',
        },
        expansion: {
            value: 'expansion',
            color: 'green',
            label: 'Expansion',
        },
        contraction: {
            value: 'contraction',
            color: 'orange',
            label: 'Contraction',
        },
        upgrade: {
            value: 'upgrade',
            color: 'green',
            label: 'Upgrade',
        },
        downgrade: {
            value: 'downgrade',
            color: 'orange',
            label: 'Downgrade',
        },
        churn: {
            value: 'churn',
            color: 'red',
            label: 'Churn',
        }

    }

    const lastUpdate = getSystemProcessing.filter(item => item.processor === 'accountActivity')[0].date
    const pageTitle = 'Billing Report'
    let [searchParams, setSearchParams] = useSearchParams();
    /* Filter States */

    const [dateFilter, setDateFilter] = useState<[Dayjs, Dayjs]>(getDateRangeFilterDefaults(searchParams, lastUpdate.subtract(1, 'day'), lastUpdate.subtract(1, 'day')))
    const [reviewedFilter, setReviewedFilter] = useState('unreviewed')
    const [effectFilter, setEffectFilter] = useState('all')

    const handleReviewedChange = (reviewed) => {
        setReviewedFilter(reviewed)
        setSearchParams({
            ...Object.fromEntries([...searchParams]),
            reviewed
        })
    }

    const handleEffectChange = (effect) => {
        setEffectFilter(effect)
        setSearchParams({
            ...Object.fromEntries([...searchParams]),
            effect
        })
    }


    const [markBillingReportCompleted] = useMutation(gql`
    mutation markBillingReportCompleted($date: Date!, $customer: String!) {
      markBillingReportCompleted(date: $date, customer: $customer) 
    }
  `)
    const [undoBillingReportCompleted] = useMutation(gql`
    mutation removeBillingReportCompleted($date: Date!, $customer: String!) {
      removeBillingReportCompleted(date: $date, customer: $customer) 
    }
  `)


    /* Filters */
    const filters: {
        label: string,
        filter: React.FC
    }[] = [{
        label: 'Date Range',
        filter: () => {
            return <div>
                <DateRangePicker presets={[
                    { label: 'Today', value: [lastUpdate.subtract(1, 'day'), lastUpdate.subtract(1, 'day')] },
                    { label: 'Yesterday', value: [lastUpdate.subtract(2, 'day'), lastUpdate.subtract(2, 'day')] },
                    { label: 'Last 7 Days', value: [lastUpdate.subtract(8, 'day'), lastUpdate.subtract(1, 'day')] },
                    { label: 'This Month', value: [lastUpdate.startOf('month'), lastUpdate.subtract(1, 'day')] },
                    { label: 'Last Month', value: [lastUpdate.subtract(1, 'month').startOf('month'), lastUpdate.subtract(1, 'month').endOf('month')] },
                ]} filter={dateFilter} setFilter={setDateFilter} lastUpdate={lastUpdate} searchParams={searchParams} setSearchParams={setSearchParams} />
            </div>
        }
    },{
        label: 'Review Status',
        filter: () => {
            return <div>
                <Select
                    value={reviewedFilter}
                    style={{width: 120}}
                    onChange={handleReviewedChange}
                    options={[
                        {value: 'unreviewed', label: 'Unreviewed'},
                        {value: 'reviewed', label: 'Reviewed'},
                        {value: 'all', label: 'All'},
                    ]}
                />
            </div>
        }
    },{
        label: 'Activity',
        filter: () => {
            return <div>
                <Select
                    value={effectFilter}
                    style={{width: 120}}
                    onChange={handleEffectChange}
                    options={[{
                        label: 'All',
                        value: 'all'
                    },{
                        label: 'Churn',
                        value: 'churn'
                    },{
                        label: 'Downgrade',
                        value: 'downgrade'
                    }]}
                />
            </div>
        }
    }]

    /* Retrieve Data */
    const { data, loading, refetch } = useQuery(GET_DATA, {variables: {
        start:  dateFilter[0],
        end: dateFilter[1],
    }})

    /* Handle Loading State */
    if(loading) {
        return <PageLoading title={pageTitle} lastUpdate={lastUpdate} />
    }
    /* Page Logic */

    const customers = uniq(data.getBillingReport.map(item => item.customer))

    const dataSource = customers.map(customer => {
        const items = data.getBillingReport.filter(item => item.customer === customer)
        const dataSourceItem = {
            customer,
            date: dayjs.utc(items[0].date),
            customerCompany: items[0].customerCompany,
            customerEmail: items[0].customerEmail,
            customerDescription: items[0].customerDescription,
            customerCreated: items[0].customerCreated,
            effects: {}
        }


        const changeItems = items.filter(item => ['changeCurrent', 'changePrevious'].includes(item.effect))
        if(changeItems.length > 0) {
            const previousMrr = sum(items.filter(item => item.effect === 'changePrevious').map(item => item.mrr))
            const currentMrr = sum(items.filter(item => item.effect === 'changeCurrent').map(item => item.mrr))
            const effect = Math.abs(currentMrr) > Math.abs(previousMrr) ? 'upgrade' : 'downgrade'
            set(dataSourceItem, ['effects', effect], {
                mrr: currentMrr + previousMrr,
                items: changeItems
            })
        }

        const effects = uniq(items.filter(item => !['changeCurrent', 'changePrevious'].includes(item.effect)).map(item => item.effect))
        if(effects.length > 0) {
            effects.forEach((effect : any) => {
                const effectItems = items.filter(item => item.effect === effect)
                set(dataSourceItem, ['effects', effect], {
                    mrr: sum(effectItems.map(item => item.mrr)),
                    items: effectItems

                })
            })
        }


        return dataSourceItem
    }).filter(customer => {
        if(has(customer, 'effects.churn') && ['all', 'churn'].includes(effectFilter)) {
            return true
        }


        if(
            has(customer, 'effects.downgrade')
            && ['all', 'downgrade'].includes(effectFilter)
            && uniq(customer.effects['downgrade'].items.map(item => item.interval)).length > 0
        ) {
            return true
        }


        return false
    }).filter(customer => {
        if(reviewedFilter === 'all') {
            return true
        }

        if(reviewedFilter === 'reviewed') {
            return Boolean(
                data.getBillingReportCompletions.filter(completed => completed.customer === customer.customer).length
                ||
                customer.date.isBefore(dayjs(dateCutoff))
            )
        }

        if(reviewedFilter === 'unreviewed') {
            return !Boolean(
                data.getBillingReportCompletions.filter(completed => completed.customer === customer.customer).length
                || customer.date.isBefore(dayjs(dateCutoff))
            )
        }

    })

    const handleMarkReviewed = async (date, customer) => {
        const { data } = await markBillingReportCompleted({
            variables: {
                date: date.format('YYYY-MM-DD'),
                customer: customer
            },
        })
        await refetch()
    }

    const handleUndoReview = async (date, customer) => {
        const { data } = await undoBillingReportCompleted({
            variables: {
                date: date.format('YYYY-MM-DD'),
                customer: customer
            },
        })
        await refetch()
    }


    const columns = [
        {
            title: 'Links',
            dataIndex: 'links',
            key: 'links',
            render: (_, item) => {
                return <div>
                    <a target="_blank" href={`https://dashboard.stripe.com/customers/${item.customer}`}><img style={{ width:24, height:24}} src={'/stripe.png'} /></a>
                    <br />
                    <a target="_blank" href={`https://my.kinsta.com/?idCompany=${item.customerCompany}`}><img style={{ width:24, height:24}} src={'/logo192.png'} /></a>
                </div>
            }
        },{
            title: 'Customer',
            dataIndex: 'customerDescription',
            key: 'customerDescription',
            render: (_, item) => {
                return <div>
                    <div style={{fontSize: 16}}> {item.customerDescription}</div>
                    <div style={{ fontFamily:"monospace", fontSize:12, color: '#777'}}>{item.customer}</div>
                    <div style={{ fontSize:12, color: '#777'}}>{item.customerEmail}</div>
                </div>
            }
        },{
            title: 'Activity',
            render: (_, item) => {
                return <div>
                    {Object.keys(item.effects).map(effect => <Tag key={effect} style={{width: 85, textAlign: 'center'}}
                                                                  color={effectMap[effect].color}>{effectMap[effect].label}</Tag>)}
                </div>
            }
        },{
            title: 'Customer Since',
            render: (_, item) => {
                return <div>{dayjs(item.customerCreated).format('YYYY-MM-DD')}</div>
            }
        }, {
            title: 'MRR Change',
            render: (_, item) => {
                return <div>{formatValue(sum(Object.keys(item.effects).map(effect => item.effects[effect].mrr))/100, ValueFormat.currency)}</div>
            }
        },
        {
            title: 'Details',
            render: (_, item) => {
                if(has(item.effects, 'churn')) {
                    return <div><strong>Removed</strong>: {item.effects['churn'].items.map(item => `${item.quantity}x ${item.priceName}`).join(', ')}</div>
                }

                const changeItems = get(item, 'effects.upgrade.items', get(item, 'effects.downgrade.items', []))
                const contractExpandItems = get(item, 'effects.expansion.items', get(item, 'effects.contraction.items', []))
                return <div>
                    <div><strong>Removed</strong>: {[
                        ...changeItems.filter(item => item.effect === 'changePrevious').map(item => `${item.quantity}x ${item.priceName}`),
                            ...contractExpandItems.filter(item => item.effect === 'contraction').map(item => `${item.quantity}x ${item.priceName}`)
                    ].join(', ')}</div>
                    <div><strong>Added</strong>: {[
                        ...changeItems.filter(item => item.effect === 'changeCurrent').map(item => `${item.quantity}x ${item.priceName}`),
                    ...contractExpandItems.filter(item => item.effect === 'expansion').map(item => `${item.quantity}x ${item.priceName}`)
                    ].join(', ')}</div>
                </div>

            }
        },{
            title: 'Reviewed',
            render: (_, item) => {
                if(item.date.isBefore(dayjs(dateCutoff))) {
                    return <div><CheckCircleOutlined /></div>
                }
                const completion = data.getBillingReportCompletions.filter(completed => completed.customer === item.customer)
                if(completion.length > 0) {
                    return <div>
                        <div style={{ display: 'flex', alignItems:'center'}}>
                            <img src={completion[0].user.picture} style={{ marginRight:6, width: 32, height:32, borderRadius: 48}} /> {completion[0].user.name}
                        </div>
                        <Button style={{ marginTop: 4}} type="default" size={"small"} icon={<UndoOutlined />} onClick={() => handleUndoReview( item.date, item.customer)}>
                            Unreview
                        </Button>

                    </div>

                }
                return <div>
                    <Button type="primary" icon={<CheckOutlined />} onClick={() => handleMarkReviewed( item.date, item.customer)}>
                        Mark Reviewed
                    </Button>
                </div>
            }
        }
    ];

    return (
        <Page frame={true} title={pageTitle} lastUpdate={lastUpdate} filters={filters}>
            <div>
                <div style={{ fontSize:12, margin: "0 0 10px 0", textTransform:'uppercase'}}>{dataSource.length} Items</div>
                <Table pagination={false} dataSource={dataSource} columns={columns} rowKey={(item : any) => item.customer} />
            </div>
        </Page>

    );
};

export default BillingReport;