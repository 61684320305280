import {Dayjs} from "./dayjs";

export default function (start : Dayjs, end: Dayjs) : Dayjs[] {
    const diff = end.diff(start, 'days')
    const dates : Dayjs[] = []
    for(let i = 0; i <= diff; i++) {
        dates.push(start.add(i, 'days'))
    }
    return dates

}