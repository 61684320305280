import React, {useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import {Input, Table} from 'antd';
import Page from '../layouts/Page';
import {useSearchParams} from 'react-router-dom'
import PageLoading from '../components/PageLoading';
import formatValue from "../utils/formatValue";
import ValueFormat from "../types/ValueFormats";
import {max, sortBy, sum, uniq} from "lodash";

const GET_DATA = gql`
query data(
        $customer: String
) {    
    getCustomerReport(
        customer: $customer
    ) {
        date
        customer
        customerfirstActive
        company
        country
        wpPrice
        activityCount
        mrr
        products
        siteLimit
        siteCount
        stagingCount
        premiumCount
        conversations
        priceName
}
     
}
`;

const BillingReport: React.FC<{ getSystemProcessing }> = ({ getSystemProcessing }) => {
    /* Get Base Properties */
    const lastUpdate = getSystemProcessing.filter(item => item.processor === 'customerData')[0].date
    const pageTitle = 'Customer Report'
    let [searchParams, setSearchParams] = useSearchParams();
    /* Filter States */

    const [cohortSearchValue, setCohortSearchValue] = useState<string>('')

    const handleCohortSearchChange = (cohort) => {
        setCohortSearchValue(cohort)
        setSearchParams({
            ...Object.fromEntries([...searchParams]),
            cohort
        })
    }




    /* Filters */
    const filters: {
        label: string,
        filter: React.FC
    }[] = [{
        label: 'Cohort Search',
        filter: () => {
            return <div>
                <Input.Search defaultValue={cohortSearchValue} enterButton onSearch={handleCohortSearchChange}/>
            </div>
        }
    }]

    /* Retrieve Data */
    const { data, loading, refetch } = useQuery(GET_DATA, {variables: {
        customer: null
    }})



    /* Handle Loading State */
    if(loading) {
        return <PageLoading title={pageTitle} lastUpdate={lastUpdate} />
    }
    /* Page Logic */
    const columns = [{
        title: 'Customer',
        render: (_, item) => {
            return <div>
                <div style={{fontSize: 16}}> <a target="_blank" href={`https://dashboard.stripe.com/customers/${item.customer}`}>{item.customer}</a></div>
                <div style={{ fontFamily:"monospace", fontSize:12, color: '#777'}}><a target="_blank" href={`https://my.kinsta.com/?idCompany=${item.company}`}>MK Link</a></div>
            </div>
        }
    },{
        title: 'Site Limit',
        key: 'siteLimit',
        dataIndex: 'siteLimit',
    },{
        title: 'Site Count',
        key: 'siteCount',
        dataIndex: 'siteCount',
        sorter: (a, b) => a.siteCount - b.siteCount,

    },{
        title: 'Site Utilization',
        key: 'siteUtilization',
        dataIndex: 'siteUtilization',
        sorter: (a, b) =>  a.siteUtilization - b.siteUtilization,
        render: item => formatValue(item, ValueFormat.percentage)

    },{
        title: 'Cohort',
        key: 'priceName',
        dataIndex: 'priceName',
    },{
        title: 'Tickets',
        key: 'conversations',
        dataIndex: 'conversations',
        render: item => item || 0,
        sorter: (a, b) =>  a.conversations - b.conversations,

    }];

    const dataSource = data.getCustomerReport.map(customer => ({
        customer: customer.customer,
        siteLimit: customer.siteLimit,
        siteCount: customer.siteCount,
        priceName: customer.priceName,
        company: customer.company,
        conversations: customer.conversations,
        siteUtilization: customer.siteLimit === 0 ? 0 : customer.siteCount / customer.siteLimit
    }))
        .filter(item => !cohortSearchValue || item.priceName.toLowerCase().includes(cohortSearchValue.toLowerCase() ))

    return (
        <Page frame={true} title={pageTitle} lastUpdate={lastUpdate} filters={filters}>
            <div>
                <div style={{ fontSize:12, margin: "0 0 10px 0", textTransform:'uppercase'}}>{dataSource.length} Items</div>
                <Table pagination={{pageSize: 100}} dataSource={dataSource} columns={columns} rowKey={(item : any) => item.customer} />

            </div>
        </Page>

    );
};

export default BillingReport;