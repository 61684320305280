import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {get} from 'lodash'
import { ApolloProvider, InMemoryCache, ApolloClient,createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {AuthProvider} from "./components/AuthContext";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const httpLink = createHttpLink({
    //uri: process.env.REACT_APP_GRAPHQL_ENDPOINT || '/graphql',
    // uri: 'http://localhost:4000/graphql',
    uri: process.env?.REACT_APP_ENV === 'development' ? 'http://localhost:4000/graphql' : process.env.REACT_APP_GRAPHQL_ENDPOINT || '/graphql'
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const localState = window.localStorage.getItem('kbiToken');
    let token
    try {
        token = JSON.parse(localState || '').token
    } catch(e) {
        token = ''
    }

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

root.render(
    <GoogleOAuthProvider clientId={get(process.env, 'REACT_APP_OAUTH_CLIENT_ID', '')}>
        <AuthProvider>
            <React.StrictMode>
                <ApolloProvider client={client}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ApolloProvider>
            </React.StrictMode>
        </AuthProvider>
    </GoogleOAuthProvider>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
