import React from "react";
import {Collapse, Typography} from "antd";
import DocsAccountHistory from "./DocsAccountHistory";
import DocsPrices from "./DocsPrices";
import DocsAccountActivity from "./DocsAccountActivity";
import {
    ArrowRightOutlined,
    BookTwoTone,
    CalculatorTwoTone,
    DatabaseTwoTone
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import { BlockMath } from 'react-katex';

const DocsRevenueChurn: React.FC = (props) => {
    const { Text, Paragraph, Title } = Typography;

    return <div>



        <Paragraph>
            <Title level={5} style={{marginTop:0}}>
                <CalculatorTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Formula
            </Title>
        </Paragraph>

        <BlockMath math="\large
        \frac{\text{MRR Lost in the Last 30 Days}}{\text{MRR 30 Days Ago}} \times 100
        "/>
        <Paragraph>
            <Title level={5}>
                <DatabaseTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Data Source
            </Title>
        </Paragraph>

        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="revenueChurn" key="dependency">
                <div style={{display: 'flex', alignItems:'center', gap: 60}}>
                    <div>
                        <ul>
                            <li>Stripe Events
                                <ul>
                                    <li><Text code>accountHistory</Text></li>
                                    <li><Text code>prices</Text></li>
                                    <li><Text code>customers</Text></li>
                                    <li><Text code>customerCountry</Text></li>
                                </ul>
                            </li>
                            <li>
                                Stripe Sigma
                                <ul>
                                    <li><Text code>usageRecords</Text></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ArrowRightOutlined style={{ fontSize: 30}} />
                    </div>
                    <div>
                        <ul>
                            <li><Text code>mrrBreakout</Text></li>
                            <li><Text code>accountActivity</Text></li>

                        </ul>
                    </div>

                </div>
            </Collapse.Panel>
        </Collapse>




        <Paragraph>
            <Title level={5}>
                <BookTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Further Reading
            </Title>
        </Paragraph>
        <Paragraph>
            <ul>
                <li>
                    <Link to="/revenue/mrr?help=yes">MRR</Link>
                </li>
            </ul>
        </Paragraph>

        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="Understanding Account History" key="account-history">
                <DocsAccountHistory />
            </Collapse.Panel>

            <Collapse.Panel header="Understanding Prices" key="prices">
                <DocsPrices />
            </Collapse.Panel>

            <Collapse.Panel header="Understanding Account Activity" key="accountActivity">
                <DocsAccountActivity />
            </Collapse.Panel>

        </Collapse>


        </div>

}

export default DocsRevenueChurn