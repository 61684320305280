import React from "react";
import {Collapse, Typography} from "antd";
import {
    BookTwoTone,
    CalculatorTwoTone,
    DatabaseTwoTone,
    ExclamationCircleTwoTone,
    InfoCircleTwoTone
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import { BlockMath } from 'react-katex';


const DicsFees: React.FC = (props) => {
    const { Text, Paragraph, Title } = Typography;

    return <div>


        <Paragraph>
            <Title level={5} style={{marginTop:0}}>
                <CalculatorTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Formula
            </Title>
        </Paragraph>

        <BlockMath math="\footnotesize \text{For applicable balance transaction fees on a given day}"/>
        <BlockMath math="\large
        \sum_{} amount "/>
        <Paragraph>
            <Title level={5}>
                <DatabaseTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Data Source
            </Title>
        </Paragraph>

        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="balanceTransactionFees" key="dependency">
                <ul>
                    <li>Stripe Balance Transactions
                        <ul>
                            <li><Text code>balanceTransactionFees</Text></li>
                        </ul>
                    </li>
                </ul>
            </Collapse.Panel>
        </Collapse>



        <Paragraph>
            <Title level={5}>
                <InfoCircleTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Details
            </Title>
        </Paragraph>

        <Paragraph>
            Any event that affects our Stripe balance (an incoming payment from a customer, a payout to our bank account,
            a refund, a dispute, etc) incurs a balance transaction. Each balance transaction may have a number of
            fees associated with it such as conversion fees, processing fees and dispute fees. The report shows the sum
            of these fees on any given day
        </Paragraph>

        <Paragraph style={{marginBottom:0}}><ExclamationCircleTwoTone twoToneColor={'#ed6605'} style={{ marginRight: 6}} /> <strong>Negative Fees</strong></Paragraph>
        <Paragraph>
            Note that for Dispute Fees it is possible to see negative values. I believe this is because during disputes we
            may receive balance back that was previously removed until the dispute was resolved.
        </Paragraph>

        <Paragraph>
            <Title level={5}>
                <BookTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Further Reading
            </Title>
        </Paragraph>

        <Paragraph>
            <ul>
                <li>
                    <Link target={'_blank'} to="https://stripe.com/docs/api/balance_transactions/object#balance_transaction_object-fee_details">Balance Transaction Fee Details</Link>
                </li>
            </ul>
        </Paragraph>

    </div>

}

export default DicsFees