import regionList from "./regionList";

export default function(permissions) {
    return Object.keys(regionList)
        .filter(region => {
            const regionPermissions = permissions.filter(permission => permission.type === 'region').map(permission => permission.permission)
            if(regionPermissions.includes('all')) {
                return true
            }

            return regionPermissions.includes(region)
        })
        .map(region => ({
            value: `region-${region}`,
            title: regionList[region].name
        }))
}