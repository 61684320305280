import React from "react";
import Page from "../layouts/Page";
import Loading from "./Loading";
import {Dayjs} from "../utils/dayjs";


const PageLoading: React.FC<{ title: string, lastUpdate?: Dayjs}> = ({ title, lastUpdate}) => {
    return <Page title={title} lastUpdate={lastUpdate}>
        <div><Loading /></div>
    </Page>

}

export default PageLoading