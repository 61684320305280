import {
    VictoryArea,
    VictoryAxis,
    VictoryBar,
    VictoryChart, VictoryContainer,
    VictoryStack,
    VictoryTooltip,
    VictoryVoronoiContainer
} from "victory";
import chartTheme from "../utils/chartTheme";
import dayjs, {Dayjs} from "../utils/dayjs";
import ChartFlyout from "./ChartFlyout";
import React from "react";
import numeral from "../utils/numeral";
import ChartHeader from "./ChartHeader";
import EmptyChart from "./EmptyChart";
import ChartData from "../types/ChartData";
import getDomainForChartPoints from "./src/getDomainForChartPoints";
import ChartSummary from "./ChartSummary";
import ValueFormat from "../types/ValueFormats";
import {sortBy, uniq} from "lodash";
import StackChartFlyout from "./StackChartFlyout";
import getDateForChart from "../utils/getDateForChart";

const Chart: React.FC<{
    data: ChartData
    displayConfig: {
        summaryDisplay:Boolean
        valueFormat: ValueFormat
        valueModifier?: (value : number) => number
    }
    chartStyles?: {
        height?: number,
        bottomMargin?: number
        topMargin?: number
    }
}> = ({
    data,
    displayConfig,
    chartStyles = {
        height: 400,
        bottomMargin: 30,
        topMargin: 5
    }
}) => {
    const defaultChartStyles = {
        height: 400,
        bottomMargin: 30,
        topMargin: 5
    }

    chartStyles  = {
        ...defaultChartStyles,
        ...chartStyles,
    }


    const visiblePoints = data.dataPoints.filter(point => point.visible)
    if(visiblePoints.length === 0) {
        return <EmptyChart />
    }

    const domain = getDomainForChartPoints(visiblePoints, chartStyles, data.stackData)

    const dateTickFormatMap = {
        day: 'YYYY MMM DD',
        week: 'YYYY MMM DD',
        month: 'YYYY MMM',
        year: 'YYYY'
    }

    const renderBaseChart = () => {
        return <VictoryArea
            style={{
                data: {
                    fill: "#6447ef", fillOpacity: 0.1, stroke: "#6447ef", strokeWidth: 3
                },
            }}
            labels={({ datum }) => " " }
            labelComponent={<VictoryTooltip
                flyoutComponent={<ChartFlyout data={data} dateTickFormat={dateTickFormatMap[data.dateGroupBy]} dateGroupBy={data.dateGroupBy} format={displayConfig.valueFormat} />}
            />}


            data={visiblePoints}
        />
    }

    const renderStackChart = () => {
        
        const stacksWithData = uniq(visiblePoints.map(point => point.stacks.map(item => item.stack)).flat())
        return <VictoryStack>
            {sortBy(data.stackData.stacks, 'value')
                .filter(stack => stacksWithData.includes(stack.value))
                .map(stackOption => {
                const stackPoints = visiblePoints.map(point => ({
                    x: point.x,
                    y: displayConfig.valueModifier ? displayConfig.valueModifier(point.stacks.filter(stack => stack.stack === stackOption.value)[0].y) : point.stacks.filter(stack => stack.stack === stackOption.value)[0].y
                }))


                return <VictoryBar
                    labels={({ datum }) => " " }
                    labelComponent={<VictoryTooltip
                        activateData={true}
                        flyoutComponent={<StackChartFlyout data={data} dateTickFormat={dateTickFormatMap[data.dateGroupBy]} dateGroupBy={data.dateGroupBy} format={displayConfig.valueFormat} />}
                    />}

                    key={`group-${stackOption.value}`}
                    data={stackPoints}
                ></VictoryBar>
            })}


        </VictoryStack>
    }
    
    return <div>
        {displayConfig.summaryDisplay ? <ChartSummary data={data} displayConfig={displayConfig} /> : null }


        <VictoryChart
            padding={{ left: 50, top: 0, right: data.stackData ? 10 : 0, bottom: 50 }}
            minDomain={{y: domain.min }}
            maxDomain={{y: domain.max }}

            theme={chartTheme({
                height: chartStyles.height
            })}
            containerComponent={data.stackData ? <VictoryContainer /> : <VictoryVoronoiContainer/>}
        >
            <VictoryAxis
                dependentAxis={ true }
                tickFormat = { x => numeral(x).format(displayConfig.valueFormat) }
                tickCount={5}

            />
            <VictoryAxis
                style={{
                    grid: { stroke: 'none' },
                }}
                tickFormat = { x => dayjs(x).format(dateTickFormatMap[data.dateGroupBy]) }
                tickCount={Math.min(8, visiblePoints.length)}
            />

            {!data.stackData ? renderBaseChart() : renderStackChart()}
        </VictoryChart>


    </div>

}

export default Chart




