export default function(points, chartStyles, stackData) : {
    min: number
    max: number
} {


    // Math.min(...points.stacks.map(stack => stack.y).flat())
    const min = stackData
        ? Math.min(...points.map(point => point.stacks.map(stack => stack.y)).flat())
        : Math.min(...points.map(item => item.y))
    const max = Math.max(...points.map(item => item.y))

    const minAbs = Math.abs(min)
    const maxAbs = Math.abs(max)
    const diff = Math.abs(maxAbs - minAbs)

    return {
        min: min - diff/chartStyles.height * chartStyles.bottomMargin,
        max: max + diff/chartStyles.height * chartStyles.topMargin
    }
}