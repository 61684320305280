import React from 'react';
import Page from "../layouts/Page";



const Home: React.FC = () => {
    const pageTitle = 'Home'


    return (
        <Page frame={true} title={pageTitle}>
            Home page coming soon
        </Page>

    );};

export default Home;