import {Route, Routes} from "react-router-dom";
import {ConfigProvider} from 'antd';
import React from 'react';
import Main from "./layouts/Main";
import SingleCentered from "./layouts/SingleCentered";
import Home from "./pages/Home";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import {gql, useQuery} from "@apollo/client";
import dayjs, {Dayjs} from "./utils/dayjs";
import ValueFormat from "./types/ValueFormats";
import UserManagement from "./pages/UserManagement";
import BillingReport from "./pages/BillingReport";
import KeyAccounts from "./pages/KeyAccounts";
import CustomerReport from "./pages/CustomerReport";
import DocsActiveSubscriptions from "./documentation/DocsActiveSubscriptions";
import DocsNetRevenue from "./documentation/DocsNetRevenue";
import DocsNetRevenueRetention from "./documentation/DocsNetRevenueRetention";
import DocsCustomerExpectedLifetime from "./documentation/DocsCustomerExpectedLifetime";
import DocsArpu from "./documentation/DocsArpu";
import DocsFees from "./documentation/DocsFees";
import DocsChurnRate from "./documentation/DocsChurnRate";
import DocsRevenueChurn from "./documentation/DocsRevenueChurn";
import DocsRefunds from "./documentation/DocsRefunds";
import DocsLtv from "./documentation/DocsLtv";
import DocsAddons from "./documentation/DocsAddons";
import DocsNewCustomers from "./documentation/DocsNewCustomers";
import DocsNewSubscriptions from "./documentation/DocsNewSubscriptions";
import DocsFailedPayments from "./documentation/DocsFailedPayments";
import ChartPage from "./pages/ChartPage";
import {ChartDataType, ChartSummaryAggregation} from "./types/ChartData";
import DocsActiveCustomers from "./documentation/DocsActiveCustomers";
import DocsMrrGrowthRate from "./documentation/DocsMrrGrowthRate";
import DocsRecurringRevenue from "./documentation/DocsRecurringRevenue";
import ChartPageRecurringRevenue from "./pages/ChartPageRecurringRevenue";

const GET_DATA = gql`
query data {

    getSystemProcessing {
        processor
        date
    }
    
   getAddonCategories {
        id
        name
    }

  }
`

const App: React.FC = () => {
    const { data, loading } = useQuery(GET_DATA)

    if(loading) {
        return <div></div>
    }

    const props = {
        pageTitle:'Active Subscriptions',
        stackByType:true,
        countries:true,
    }


    const getSystemProcessing : {processor: string, date: Dayjs}[] = data.getSystemProcessing ? data.getSystemProcessing.map(item => ({
        processor: item.processor,
        date: dayjs.utc(item.date)
    })) : null

    return (
      <div>
              <ConfigProvider theme={{
                      token: {
                          colorPrimary: '#5333ed',
                      },
                  }}
              >

          <Routes>
              <Route path ='/'
                  element={
                      <ProtectedRoute>
                          <Main />
                      </ProtectedRoute>
                  }
              >
                  <Route path="/" element={<Home />} />

                  <Route key="/revenue/mrr-breakout" path="/revenue/mrr-breakout" element={<ChartPage
                      chartConfig = {{
                          dataAggregator: 'SUM(value)',
                          dateGroupBy: true,
                          locations: false,
                          stackByType: true,
                          dataType: ChartDataType.change,
                          summaryAggregation: ChartSummaryAggregation.sum,
                      }}
                      displayConfig={{
                          valueFormat: ValueFormat.integer,
                          summaryDisplay: true
                      }}
                      processor={'mrrBreakout'}
                      docs={{
                          title: "About MRR Breakout",
                          element: <DocsRecurringRevenue />
                      }}
                      title={'MRR Breakout'}
                      systemProcessing={getSystemProcessing}
                      dimensions={[{
                        name: 'type',
                        filterLabel: 'Type',
                        options: [{
                            value: 'new',
                            label: 'New'
                        },{
                            value: 'upgrade',
                            label: 'Upgrade'
                        },{
                            value: 'expansion',
                            label: 'Expansion'
                        },{
                            value: 'reactivation',
                            label: 'Reactivation'
                        },{
                            value: 'downgrade',
                            label: 'Downgrade'
                        },{
                            value: 'contraction',
                            label: 'Contraction'
                        },{
                            value: 'churn',
                            label: 'Churn'
                        },{
                            value: 'usageIncrease',
                            label: 'Usage Increase'
                        },{
                            value: 'usageDecrease',
                            label: 'usageDecrease'
                        },]
                      }]}



                  />} />


                  <Route
                      path="/revenue/mrr"
                      key="/revenue/mrr"
                      element={<ChartPageRecurringRevenue
                          chartStyles = {{
                            height:200
                          }}
                          chartConfig = {{
                              dataAggregator: 'SUM(value)',
                              dateGroupBy: true,
                              locations: true,
                              stackByType: true,
                              dataType: ChartDataType.state,
                              summaryAggregation: ChartSummaryAggregation.state,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.currency,
                              summaryDisplay: true
                          }}
                          processor={'chartMrr'}
                          docs={{
                              title: "About MRR",
                              element: <DocsRecurringRevenue />
                          }}
                          title={'Monthly Recurring Revenue'}
                          systemProcessing={getSystemProcessing}
                          dimensions={[{
                            name: 'serviceGroup',
                            filterLabel: 'Service Group',
                            options: [{
                                value: 'paas',
                                label: 'PaaS'
                            },{
                                value: 'wp',
                                label: 'WordPress'
                            }]
                          },{
                            name: 'influence',
                            filterLabel: 'Influence',
                            options: [{
                                value: 'sales',
                                label: 'Sales'
                            },{
                                value: 'affiliate',
                                label: 'Affiliate'
                            },{
                                value: 'organic',
                                label: 'Organic'
                            },{
                                value: 'both',
                                label: 'Sales & Affiliate'
                            }]
                          },{
                            name: 'billingSetup',
                            filterLabel: 'Billing Setup',
                            options: [{
                                value: 'regular',
                                label: 'Regular'
                            },{
                                value: 'custom',
                                label: 'Custom'
                            },{
                                value: 'wire',
                                label: 'Wire'
                            }]
                          }]}

                      />} />


                  <Route
                      path="/revenue/arr"
                      key="/revenue/arr"
                      element={<ChartPageRecurringRevenue
                          chartConfig = {{
                              dataAggregator: 'SUM(value)',
                              dateGroupBy: true,
                              locations: true,
                              stackByType: true,
                              dataType: ChartDataType.state,
                              summaryAggregation: ChartSummaryAggregation.state,
                          }}
                          chartStyles={{
                            height:200,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.currency,
                              summaryDisplay: true,
                              valueModifier: (value) => value * 12
                          }}
                          processor={'chartMrr'}
                          docs={{
                              title: "About ARR",
                              element: <DocsRecurringRevenue />
                          }}
                          title={'Annual Recurring Revenue'}
                          systemProcessing={getSystemProcessing}
                          dimensions={[{
                            name: 'serviceGroup',
                            filterLabel: 'Service Group',
                            options: [{
                                value: 'paas',
                                label: 'PaaS'
                            },{
                                value: 'wp',
                                label: 'WordPress'
                            }]
                          },{
                            name: 'influence',
                            filterLabel: 'Influence',
                            options: [{
                                value: 'sales',
                                label: 'Sales'
                            },{
                                value: 'affiliate',
                                label: 'Affiliate'
                            },{
                                value: 'organic',
                                label: 'Organic'
                            },{
                                value: 'both',
                                label: 'Sales & Affiliate'
                            }]
                          },{
                            name: 'billingSetup',
                            filterLabel: 'Billing Setup',
                            options: [{
                                value: 'regular',
                                label: 'Regular'
                            },{
                                value: 'custom',
                                label: 'Custom'
                            },{
                                value: 'wire',
                                label: 'Wire'
                            }]
                          }]}

                      />} />


<Route
                      path="/revenue/mrr-growth-rate"
                      key="/revenue/mrr-growth-rate"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value)',
                              dateGroupBy: false,
                              locations: true,
                              stackByType: true,
                              dataType: ChartDataType.change,
                              summaryAggregation: ChartSummaryAggregation.average,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.percentage,
                              summaryDisplay: true
                          }}
                          customQuery={'mrrGrowthRate'}
                          processor={'chartMrr'}
                          docs={{
                              title: "About MRR Growth Rate",
                              element: <DocsMrrGrowthRate />
                          }}
                          title={'MRR Growth Rate'}
                          systemProcessing={getSystemProcessing}
                          dimensions={[{
                            name: 'serviceGroup',
                            filterLabel: 'Service Group',
                            options: [{
                                value: 'paas',
                                label: 'PaaS'
                            },{
                                value: 'wp',
                                label: 'WordPress'
                            }]
                          },{
                            name: 'influence',
                            filterLabel: 'Influence',
                            options: [{
                                value: 'sales',
                                label: 'Sales'
                            },{
                                value: 'affiliate',
                                label: 'Affiliate'
                            },{
                                value: 'organic',
                                label: 'Organic'
                            },{
                                value: 'both',
                                label: 'Sales & Affiliate'
                            }]
                          },{
                            name: 'billingSetup',
                            filterLabel: 'Billing Setup',
                            options: [{
                                value: 'regular',
                                label: 'Regular'
                            },{
                                value: 'custom',
                                label: 'Custom'
                            },{
                                value: 'wire',
                                label: 'Wire'
                            }]
                          }]}

                      />} />

                <Route
                      path="/revenue/nrr"
                      key="/revenue/nrr"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'AVG(value)',
                              dateGroupBy: true,
                              locations: false,
                              stackByType: false,
                              dataType: ChartDataType.change,
                              summaryAggregation: ChartSummaryAggregation.average,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.percentage,
                              summaryDisplay: true
                          }}
                          processor={'chartNrr'}
                          docs={{
                              title: "About Net Revenue Retention",
                              element: <DocsNetRevenueRetention />
                          }}
                          title={'Net Revenue Retention'}
                          systemProcessing={getSystemProcessing}
                      />} />



                  <Route
                      path="/global-revenue/net-revenue"
                      key="/global-revenue/net-revenue"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value)',
                              dateGroupBy: true,
                              locations: false,
                              stackByType: false,
                              dataType: ChartDataType.change,
                              summaryAggregation: ChartSummaryAggregation.sum,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.currency,
                              summaryDisplay: true
                          }}
                          processor={'chartNetRevenue'}
                          docs={{
                              title: "About Net Revenue",
                              element: <DocsNetRevenue />
                          }}
                          title={'Net Revenue'}
                          systemProcessing={getSystemProcessing}
                      />} />

                  <Route
                      path="/global-revenue/stripe-fees"
                      key="/global-revenue/stripe-fees"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value)',
                              dateGroupBy: true,
                              locations: false,
                              stackByType: false,
                              dataType: ChartDataType.change,
                              summaryAggregation: ChartSummaryAggregation.sum,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.currency,
                              summaryDisplay: true
                          }}
                          processor={'chartStripeFees'}
                          docs={{
                              title: "About StripeFees",
                              element: <DocsFees />
                          }}
                          title={'Stripe Fees'}
                          systemProcessing={getSystemProcessing}
                      />} />


                  <Route
                      path="/global-revenue/refunds"
                      key="/global-revenue/refunds"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value)',
                              dateGroupBy: true,
                              locations: false,
                              stackByType: false,
                              dataType: ChartDataType.change,
                              summaryAggregation: ChartSummaryAggregation.sum,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.currency,
                              summaryDisplay: true
                          }}
                          processor={'chartRefunds'}
                          docs={{
                              title: "About Refund",
                              element: <DocsRefunds />
                          }}
                          title={'Refunds'}
                          systemProcessing={getSystemProcessing}


                      />} />

                  <Route
                      path="/revenue/arpu"
                      key="/revenue/arpu"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value * weight) / SUM(weight)',
                              dateGroupBy: true,
                              locations: true,
                              stackByType: true,
                              dataType: ChartDataType.state,
                              summaryAggregation: ChartSummaryAggregation.state,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.currency,
                              summaryDisplay: true
                          }}
                          processor={'chartArpu'}
                          docs={{
                              title: "About Average Revenue Per User",
                              element: <DocsArpu />
                          }}
                          title={'ARPU'}
                          systemProcessing={getSystemProcessing}
                          dimensions={[{
                                name: 'serviceGroup',
                              filterLabel: 'Service Group',
                              options: [{
                                  value: 'wp',
                                  label: 'WordPress'
                              },{
                                  value: 'paas',
                                  label: 'PaaS'
                              }]
                          }]}

                      />} />

                  <Route
                      path="/revenue/churn-rate"
                      key="/revenue/churn-rate"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value * weight) / SUM(weight)',
                              dateGroupBy: true,
                              locations: true,
                              stackByType: false,
                              dataType: ChartDataType.state,
                              summaryAggregation: ChartSummaryAggregation.state,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.percentage,
                              summaryDisplay: true
                          }}
                          processor={'chartChurnRate'}
                          docs={{
                              title: "About Churn Rate",
                              element: <DocsChurnRate />
                          }}
                          title={'Churn Rate'}
                          systemProcessing={getSystemProcessing}
                      />} />

                  <Route
                      path="/revenue/revenue-churn"
                      key="/revenue/revenue-churn"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value * weight) / SUM(weight)',
                              dateGroupBy: true,
                              locations: true,
                              stackByType: false,
                              dataType: ChartDataType.state,
                              summaryAggregation: ChartSummaryAggregation.state,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.percentage,
                              summaryDisplay: true
                          }}
                          processor={'chartRevenueChurn'}
                          docs={{
                              title: "About Revenue Churn",
                              element: <DocsRevenueChurn />
                          }}
                          title={'Revenue Churn'}
                          systemProcessing={getSystemProcessing}
                      />} />



                  <Route
                      path="/revenue/ltv"
                      key="/revenue/ltv"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value * weight) / SUM(weight)',
                              dateGroupBy: true,
                              locations: true,
                              stackByType: true,
                              dataType: ChartDataType.state,
                              summaryAggregation: ChartSummaryAggregation.state,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.currency,
                              summaryDisplay: true
                          }}
                          processor={'chartLtv'}
                          docs={{
                              title: "About Lifetime Value",
                              element: <DocsLtv />
                          }}
                          title={'Lifetime Value'}
                          systemProcessing={getSystemProcessing}
                      />} />


                  <Route
                      path="/revenue/addons"
                      key="/revenue/addons"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value)',
                              dateGroupBy: true,
                              locations: false,
                              stackByType: true,
                              dataType: ChartDataType.state,
                              summaryAggregation: ChartSummaryAggregation.state,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.currency,
                              summaryDisplay: true
                          }}
                          processor={'chartAddonMrr'}
                          docs={{
                              title: "About Addons",
                              element: <DocsAddons />
                          }}
                          title={'Addon MRR'}
                          systemProcessing={getSystemProcessing}
                          dimensions={[{
                            name: 'addon',
                            filterLabel: 'Addon',
                              options: data.getAddonCategories.map(addonCategory => ({
                                  value: addonCategory.id,
                                  label: addonCategory.name
                              }))
                          }]}


                      />} />



                  {/* <Route
                      path="/revenue/commissions"
                      key="/revenue/commissions"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value)',
                              dateGroupBy: true,
                              locations: false,
                              stackByType: true,
                              dataType: ChartDataType.change,
                              summaryAggregation: ChartSummaryAggregation.sum,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.currency,
                              summaryDisplay: true
                          }}
                          processor={'commissionByCountry'}
                          docs={{
                              title: "About Commissions",
                              element: <DocsCommissions />
                          }}
                          title={'Commissions'}
                          systemProcessing={getSystemProcessing}

                      />} /> */}


                <Route
                      path="/activity/cel"
                      key="/activity/cel"
                      element={<ChartPage
                          chartConfig = {{
                            dataAggregator: 'SUM(value * weight) / SUM(weight)',
                            dateGroupBy: true,
                            locations: true,
                            stackByType: false,
                              dataType: ChartDataType.change,
                              summaryAggregation: ChartSummaryAggregation.average,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.decimal,
                              summaryDisplay: true
                          }}
                          processor={'chartCel'}
                          docs={{
                              title: "About Customer Expected Lifetime",
                              element: <DocsCustomerExpectedLifetime />
                          }}
                          title={'Customer Expected Lifetime'}
                          systemProcessing={getSystemProcessing}
                      />} />

                 <Route key="/activity/customer-report" path="/activity/customer-report" element={<CustomerReport getSystemProcessing={getSystemProcessing}  />} />
                
                  <Route
                      path="/activity/active-customers"
                      key="/activity/active-customers"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value)',
                              dateGroupBy: true,
                              locations: true,
                              stackByType: false,
                              dataType: ChartDataType.state,
                              summaryAggregation: ChartSummaryAggregation.state,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.integer,
                              summaryDisplay: true
                          }}
                          processor={'chartActiveCustomers'}
                          docs={{
                              title: "About Active Customers",
                              element: <DocsActiveCustomers />
                          }}
                          title={'Active Customers'}
                          systemProcessing={getSystemProcessing}
                      />} />
                  <Route
                      path="/activity/new-customers"
                      key="/activity/new-customers"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value)',
                              dateGroupBy: true,
                              locations: true,
                              stackByType: true,
                              dataType: ChartDataType.change,
                              summaryAggregation: ChartSummaryAggregation.sum,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.integer,
                              summaryDisplay: true
                          }}
                          processor={'chartNewCustomers'}
                          docs={{
                              title: "About New Customers",
                              element: <DocsNewCustomers />
                          }}
                          title={'New Customers'}
                          systemProcessing={getSystemProcessing}
                          dimensions={[{
                            name: 'productGroup',
                            filterLabel: 'Product Group',
                            options: [{
                                value: 'wp',
                                label: 'WordPress'
                            },{
                                value: 'db',
                                label: 'Database'
                            },{
                                value: 'app',
                                label: 'Application'
                            }]
                        }]}

                      />} />

                  <Route
                      path="/activity/active-subscriptions"
                      key="/activity/active-subscriptions"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value)',
                              dateGroupBy: true,
                              locations: true,
                              stackByType: true,
                              dataType: ChartDataType.state,
                              summaryAggregation: ChartSummaryAggregation.state,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.integer,
                              summaryDisplay: true
                          }}
                          processor={'chartActiveSubscriptions'}
                          docs={{
                              title: "About Active Subscriptions",
                              element: <DocsActiveSubscriptions />
                          }}
                          dimensions={[{
                            name: 'productGroup',
                              filterLabel: 'Product Group',
                              options: [{
                                  value: 'wp',
                                  label: 'WordPress'
                              },{
                                  value: 'db',
                                  label: 'Database'
                              },{
                                  value: 'app',
                                  label: 'Application'
                              }]
                          }]}
                          title={'Active Subscriptions'}
                          systemProcessing={getSystemProcessing}
                      />} />

                  <Route
                      path="/activity/new-subscriptions"
                      key="/activity/new-subscriptions"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value)',
                              dateGroupBy: true,
                              locations: true,
                              stackByType: true,
                              dataType: ChartDataType.change,
                              summaryAggregation: ChartSummaryAggregation.sum,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.integer,
                              summaryDisplay: true
                          }}
                          processor={'chartNewSubscriptions'}
                          docs={{
                              title: "About New Subscriptions",
                              element: <DocsNewSubscriptions />
                          }}
                          dimensions={[{
                             name: 'productGroup',
                              filterLabel: 'Product Group',
                              options: [{
                                  value: 'wp',
                                  label: 'WordPress'
                              },{
                                  value: 'db',
                                  label: 'Database'
                              },{
                                  value: 'app',
                                  label: 'Application'
                              }]
                          }]}
                          title={'New Subscriptions'}
                          systemProcessing={getSystemProcessing}
                      />} />


{/* 
                  <Route
                      path="/infrastructure/gcp-grouped"
                      key="/infrastructure/gcp-grouped"
                      element={<ChartPage
                          chartConfig = {{
                              dataAggregator: 'SUM(value)',
                              dateGroupBy: true,
                              locations: false,
                              stackByType: true,
                              dataType: ChartDataType.change,
                              summaryAggregation: ChartSummaryAggregation.sum,
                          }}
                          displayConfig={{
                              valueFormat: ValueFormat.currency,
                              summaryDisplay: true
                          }}
                          processor={'gcpGroupCosts'}
                          title={'GCP Cost Groups'}
                          systemProcessing={getSystemProcessing}
                          types={{
                              filterLabel: 'Cost Group',
                              options: [{
                                  value: 'Backup',
                                  label: 'Backup'
                              },{
                                  value: 'CPU',
                                  label: 'CPU'
                              },{
                                  value: 'Disk',
                                  label: 'Disk'
                              },{
                                  value: 'Egress',
                                  label: 'Egress'
                              },{
                                  value: 'Kubernetes',
                                  label: 'Kubernetes'
                              },{
                                  value: 'Other',
                                  label: 'Other'
                              },{
                                  value: 'RAM',
                                  label: 'RAM'
                              }]
                          }}
                      />} /> */}


                  <Route key="/fraud/fraud-detection" path="/fraud/fraud-detection" element={<ChartPage
                      chartConfig = {{
                          dataAggregator: 'SUM(value)',
                          dateGroupBy: true,
                          locations: false,
                          stackByType: false,
                          dataType: ChartDataType.change,
                          summaryAggregation: ChartSummaryAggregation.sum,
                      }}
                      displayConfig={{
                          valueFormat: ValueFormat.integer,
                          summaryDisplay: true
                      }}
                      processor={'chartFraud'}
                      docs={{
                          title: "About Fraud Detection",
                          element: <DocsActiveCustomers />
                      }}
                      title={'Fraud Detection'}
                      systemProcessing={getSystemProcessing}
                  />} />


                    <Route key="/billing-accounts/failed-payments" path="/billing-accounts/failed-payments" element={<ChartPage
                      chartConfig = {{
                          dataAggregator: 'SUM(value)',
                          dateGroupBy: true,
                          locations: false,
                          stackByType: true,
                          dataType: ChartDataType.change,
                          summaryAggregation: ChartSummaryAggregation.sum,
                      }}
                      displayConfig={{
                          valueFormat: ValueFormat.integer,
                          summaryDisplay: true
                      }}
                      processor={'chartFailedPayments'}
                      docs={{
                          title: "About Failed Payments",
                          element: <DocsFailedPayments />
                      }}
                      title={'Failed Payments'}
                      systemProcessing={getSystemProcessing}
                      dimensions={[{
                        name: 'attempt',
                        filterLabel: 'Attempt',
                        options: [{
                            value: '1',
                            label: 'Attempt 1'
                        },{
                            value: '2',
                            label: 'Attempt 2'
                        },{
                            value: '3',
                            label: 'Attempt 3'
                        },{
                            value: '4',
                            label: 'Attempt 4'
                        }]
                      }]}

                  />} />

                  <Route key="/billing-accounts/billing-report" path="/billing-accounts/billing-report" element={<BillingReport getSystemProcessing={getSystemProcessing}  />} />
                  <Route key="/billing-accounts/key-accounts" path="/billing-accounts/key-accounts" element={<KeyAccounts getSystemProcessing={getSystemProcessing}  />} />
                  


                  <Route key="/admin/user-management" path="/admin/user-management" element={<UserManagement  />} />

              </Route>


              <Route
                  element={
                      <div>
                          <SingleCentered />
                      </div>
                  }
              >
                  <Route path="/login" element={<Login />} />
              </Route>


          </Routes>
              </ConfigProvider>
      </div>

  );
};

export default App;