import React from "react";
import {Collapse, Table, Typography} from "antd";
import {ArrowRightOutlined, BookTwoTone, CalculatorTwoTone, DatabaseTwoTone, InfoCircleTwoTone} from "@ant-design/icons";
import { BlockMath } from 'react-katex';
import {Link} from "react-router-dom";

const DocsNetRevenue: React.FC = (props) => {
    const { Text, Paragraph, Title } = Typography;


    return <div>

<Paragraph>
            <Title level={5}>
                <InfoCircleTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Details
            </Title>
        </Paragraph>

        <Paragraph>
            This data shows the number of failed payment attempts up to 4.
        </Paragraph>



        </div>

}

export default DocsNetRevenue