import React, {ReactElement, useEffect, useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import dayjs, {Dayjs} from "../utils/dayjs";
import Page from '../layouts/Page';
import {useSearchParams} from 'react-router-dom'
import getDateRangeFilterDefaults from "../utils/getDateRangeFilterDefaults";
import PageLoading from '../components/PageLoading';
import DateGroupBySelector from "../components/DateGroupBySelector";
import DateGroupBy from "../types/DateGroupBy";
import getDateGroupByFilterDefaults from "../utils/getDateGroupByFilterDefaults";
import DateRangePicker from "../components/DateRangePicker";
import TypeSelector from "../components/TypeSelector";
import DimensionSelector from "../components/DimensionSelector";
import ValueFormat from "../types/ValueFormats";
import getTypeFilterDefault from "../utils/getTypeFilterDefault";
import LocationSelector from "../components/LocationSelector";
import getLocationFilterDefault from "../utils/getLocationFilterDefault";
import regionList from "../utils/regionList";
import Chart from "../components/Chart";
import {ChartDataType, ChartSummaryAggregation} from "../types/ChartData";
import getStackDimensionStateDefault from "../utils/getStackDimensionStateDefault";
import {omit, startCase, sum, uniq} from "lodash";
import getDateSeries from "../utils/getDateSeries";
import {ColumnsType} from "antd/es/table";
import formatValue from "../utils/formatValue";
import {Table} from "antd";
import { LockTwoTone } from '@ant-design/icons';
import getDimensionsFilterDefaults from '../utils/getDimensionsFilterDefaults';

const ChartPageRecurringRevenue: React.FC<{
    chartConfig: {
        dataAggregator: string
        dateGroupBy: Boolean
        locations: Boolean
        stackByType: Boolean
        dataType: ChartDataType
        summaryAggregation: ChartSummaryAggregation
    }
    chartStyles?: {
        height?: number,
        bottomMargin?: number
        topMargin?: number
    }
    displayConfig: {
        valueFormat: ValueFormat
        summaryDisplay: Boolean
        valueModifier?: (value : number) => number
    }
    customQuery?: string | null
    processor: string
    docs?: {
        title: string,
        element: ReactElement
    }
    title: string,
    systemProcessing: {
        processor: string
        date: Dayjs
    }[]
    defaultStart?: (date: Dayjs) => Dayjs
    defaultEnd?: (date: Dayjs) => Dayjs
    defaultDateGroupBy?: DateGroupBy
    types?: {
        filterLabel: string,
        options: {
            value: string
            label: string
        }[]
    }
    dimensions?: {
        name: string,
        filterLabel: string,
        options: {
            value: string
            label: string
        }[]
    }[]
}> = ({
    chartConfig,
    displayConfig = {
        valueFormat: ValueFormat.integer,
        summaryDisplay: true,
        valueModifier: (value) => value
    },
    chartStyles = {
       height: 400,
       bottomMargin: 30,
       topMargin: 5
    },
    customQuery = null,
    processor,
    docs = {
        title: null,
        element: null
    },
    title,
    systemProcessing,
    defaultStart = date => date.subtract(30, 'days'),
    defaultEnd = date => date.subtract(1, 'days'),
    defaultDateGroupBy = DateGroupBy.day,
    types = null,
    dimensions = []
}) => {
    const lastUpdate = systemProcessing.filter(item => item.processor === processor)[0].date
    const [searchParams, setSearchParams] = useSearchParams();
    const [dateFilter, setDateFilter] = useState<[Dayjs, Dayjs]>(getDateRangeFilterDefaults(searchParams, defaultStart(lastUpdate), defaultEnd(lastUpdate)))
    const [dateGroupByFilter, setDateGroupByFilter] = useState<DateGroupBy>(getDateGroupByFilterDefaults(searchParams, defaultDateGroupBy))
    const [typeFilter, setTypeFilter] = useState<string[]>(getTypeFilterDefault(searchParams, types))
    const [locationFilter, setLocationFilter] = useState<{
        filter: Boolean,
        countries: string[],
        regions: string[]
    }>(getLocationFilterDefault(searchParams))
    const [stackDimensionState, setStackDimensionState] = useState<string>(getStackDimensionStateDefault(searchParams))

    const [dimensionsFilter, setDimensionsFiler] = useState<{ [key: string]: any }>(getDimensionsFilterDefaults(searchParams, dimensions))

    const filters: {
        label: string,
        filter: React.FC
    }[] = [{
        label: 'Date Range',
        filter: () => {
            return <div>
                <DateRangePicker filter={dateFilter} setFilter={setDateFilter} lastUpdate={lastUpdate} searchParams={searchParams} setSearchParams={setSearchParams} />
            </div>
        }
    }]

    if(chartConfig.dateGroupBy) {
        filters.push({
            label: 'Show By',
            filter: () => {
                return <div>
                    <DateGroupBySelector filter={dateGroupByFilter} setFilter={setDateGroupByFilter} searchParams={searchParams} setSearchParams={setSearchParams} />
                </div>
            }
        })
    }

    if(chartConfig.locations) {
        filters.push({
            label: 'Locations',
            filter: () => {
                return <div>
                    <LocationSelector filter={locationFilter} setFilter={setLocationFilter} searchParams={searchParams} setSearchParams={setSearchParams} />
                </div>
            }
        })
    }


    dimensions.forEach(dimension => {
        filters.push({
            label: dimension.filterLabel,
            filter: () => {
                return <div>
                    <DimensionSelector allowStack={chartConfig.stackByType} stackDimensionState={stackDimensionState} setStackDimensionState={setStackDimensionState} dimension={dimension} filter={dimensionsFilter} setFilter={setDimensionsFiler} searchParams={searchParams} setSearchParams={setSearchParams}/>
                </div>
            }
        })

    })


    const queryVariables : {
      customQuery: string | null
      processor: string
      start: string
      end: string
      dateGroupBy: string
      dataType: string
      summaryAggregation: string
      dataAggregator: string
      stackData: {
            stackField: string,
          stacks: {
              value: string
              label: string
          }[]
      } | null
      filters:  {
          field: string
          values: string[]
      }[]
    } = {
        customQuery,
        processor,
        start:  dateFilter[0].format('YYYY-MM-DD HH:mm:ss'),
        end: dateFilter[1].format('YYYY-MM-DD HH:mm:ss'),
        dateGroupBy: dateGroupByFilter,
        dataType: chartConfig.dataType,
        summaryAggregation: chartConfig.summaryAggregation,
        dataAggregator: chartConfig.dataAggregator,
        stackData: stackDimensionState !== ''  ? {
            stackField: stackDimensionState,
            stacks: dimensions.filter(item => item.name === stackDimensionState)[0].options
        } : null,
        filters: [],
    }

    const queryParams = [
        ['start', 'Date!'],
        ['end', 'Date!'],
        ['customQuery', 'String'],
        ['processor', 'String!'],
        ['dateGroupBy', 'DateGroupBy!'],
        ['dataType', 'ChartDataType!'],
        ['summaryAggregation', 'ChartSummaryAggregation!'],
        ['dataAggregator', 'String'],
        ['stackData', 'InputStackData'],
        ['filters', '[ChartFilter]'],
    ]
    

    if(types && typeFilter.length > 0) {
        queryVariables.filters.push({
            field: 'type',
            values: typeFilter
        })
    }

    if(dimensions && Object.values(dimensionsFilter).map(item => item.length).filter(item => item !== 0).length >0 ) {
        Object.keys(dimensionsFilter).forEach(filter => {
            if(dimensionsFilter[filter].length === 0) {
                return
            }

            queryVariables.filters.push({
                field: filter,
                values: dimensionsFilter[filter]
            })        
     
        })
    }
    

    if(customQuery) {
        queryVariables['customQuery'] = customQuery
    }

    if(chartConfig.locations && locationFilter.filter) {
        queryVariables.filters.push({
            field: 'country',
            values: [
                ...locationFilter.regions.map(region => regionList[region].countries).flat(),
                ...locationFilter.countries
            ]
        })
    }


    let GET_DATA = gql`
        query data (
            ${queryParams.map(item => `$${item[0]}: ${item[1]}`).join('\n')}
        ) {
        
           mrrBreakout(
        start: $start, 
        end: $end,
        filters: $filters
        dateGroupBy: $dateGroupBy
   ) {
        date
        type
        country
        value
   }


            data: getChartData(
                ${queryParams.map(item => `${item[0]}: $${item[0]}`).join('\n')}
            ) {
               bounds {
                    visibleDates {
                        start
                        end
                    }
                    visibleMinimum
                    visibleMaximum
                    allDates {
                        start
                        end
                    }             
                    previousPeriod {
                        start
                        end
                    }  
               }
               dateGroupBy
               summary {
                    aggregation
                    comparisonType
                    currentValue
                    previousValue   
               }
               stackData {
                    stackField
                    stacks {
                        label
                        value
                    }
               }
               dataPoints {
                    x
                    visible
                    dateRange {
                        start
                        end
                    }
                    y
                    stacks {
                        stack
                        y
                    }
               }
            }
        }`

    const { data, loading } = useQuery(GET_DATA, {variables: queryVariables})

    if(loading) {
        return <PageLoading title={title} lastUpdate={lastUpdate} />
    }

    const chartData = {
        ...data.data,
        bounds: {
            ...data.data.bounds,
            visibleMaximum: displayConfig.valueModifier ? displayConfig.valueModifier(data.data.bounds.visibleMaximum) : data.data.bounds.visibleMaximum,
            visibleMinimum: displayConfig.valueModifier ? displayConfig.valueModifier(data.data.bounds.visibleMinimum) : data.data.bounds.visibleMinimum
        },
        dataPoints: data.data.dataPoints.map(point => ({
            ...point,
            y: displayConfig.valueModifier ? displayConfig.valueModifier(point.y) : point.y
        })),
        summary: {
            ...data.data.summary,
            currentValue: displayConfig.valueModifier ? displayConfig.valueModifier(data.data.summary.currentValue) : data.data.summary.currentValue,
            previousValue: displayConfig.valueModifier ? displayConfig.valueModifier(data.data.summary.previousValue) : data.data.summary.previousValue
        }
    }
    

    const mrrBreakout = data.mrrBreakout.map(item => ({ ...item, value: parseInt(item.value)}))

    let breakoutFields = [
        'new',
        'reactivation',
        'upgrade',
        'expansion',
        'downgrade',
        'contraction',
        'churn',
        'usageIncrease',
        'usageDecrease',
    ]

    const breakoutFieldsMap = {
        new: 'New',
        reactivation: 'Reactivation',
        upgrade: 'Upgrade',
        expansion: 'Expansion',
        downgrade: 'Downgrade',
        contraction: 'Contraction',
        churn: 'Churn',
        usageIncrease: 'PaaS Usage Increase',
        usageDecrease: 'PaaS Usage Decrease'
    }

    let mrrBreakoutFormat = 'YYYY-MM-DD'
    let mrrBreakoutDisplayFormat = 'MMM DD'
    if(dateGroupByFilter === 'month' || ( dateGroupByFilter === 'day' && dateFilter[1].diff(dateFilter[0], 'days') > 90 ) ) {
        mrrBreakoutFormat = 'YYYY-MM'
        mrrBreakoutDisplayFormat = "'YY MMM"
    }

    if(dateGroupByFilter === 'year') {
        mrrBreakoutFormat = 'YYYY'
        mrrBreakoutDisplayFormat = 'YYYY'
    }

    interface DataType {
        [key: string]: any
    }


    const tableData: DataType[] = breakoutFields.filter(field => {
        if(typeFilter.length !== 1) {
            return true
        }

        if(typeFilter[0] === 'wp' && ['usageIncrease', 'usageDecrease'].includes(field)) {
            return false
        }

        if(typeFilter[0] === 'paas' && !['usageIncrease', 'usageDecrease'].includes(field)) {
            return false
        }


        return true
    }).map(effect => {
        return {
            breakout: breakoutFieldsMap[effect],
            ...Object.fromEntries(getDateSeries(dateFilter[0], dateFilter[1])
                .map(date => {
                    const value = mrrBreakout.filter(item => item.type === effect && item.date === date.format(mrrBreakoutFormat))[0]?.value || 0
                    return [
                        date.format(mrrBreakoutFormat),
                        displayConfig.valueModifier ? displayConfig.valueModifier(value) : value
                    ]
                }))
        }
    })

    tableData.push({
        breakout: 'Change',
        ...Object.fromEntries(uniq(mrrBreakout.map(item => item.date)).map(date => {
            return [
                date,
                sum(mrrBreakout
                    .filter(item => item.date === date && breakoutFields.includes(item.type))
                    .filter(item => {
                        if(typeFilter.length !== 1) {
                            return true
                        }

                        if(typeFilter[0] === 'wp' && ['usageIncrease', 'usageDecrease'].includes(item.type)) {
                            return false
                        }

                        if(typeFilter[0] === 'paas' && !['usageIncrease', 'usageDecrease'].includes(item.type)) {
                            return false
                        }


                        return true
                    })
                    .map(item => displayConfig.valueModifier ? displayConfig.valueModifier(item.value) : item.value ))
            ]
        }))
    })

    const fixedColumnWidth = 165
    const columns: ColumnsType<DataType> = [
        {
            title: 'Breakout',
            width: fixedColumnWidth,
            fixed: 'left',
            key: 'breakout',
            dataIndex: 'breakout'
        },
        ...Object.keys(omit(tableData[0], 'breakout')).map(item => ({
            title: dayjs(item).format(mrrBreakoutDisplayFormat),
            width: 140,
            dataIndex: item,
            render: (item, row) => {
                const color = item === 0 || !item ? '#b8b8d9' : item > 0 ? '#12c457' : '#e84c85'
                return <div style={{color}} onMouseEnter={(e) => {} }>
                    {formatValue(item, ValueFormat.currency)}
                </div>
            }
        }))
    ];
    const renderBreakout = () => {
        return <div>
            <style dangerouslySetInnerHTML={{ __html: `.flippedScroll .ant-table-wrapper .ant-table-container::before, .flippedScroll .ant-table-wrapper .ant-table-container::after { width:${fixedColumnWidth}px }` }} />

            <div className={"flippedScroll"}>
                <Table size={'small'} pagination={false} columns={columns} dataSource={tableData} scroll={{ x: 1300 }} rowKey={(record) => record.breakout } />
            </div>
        </div>

    }

    return (
        <Page frame={true} docs={docs} title={title} lastUpdate={lastUpdate} filters={filters}>
            <Chart
                data={chartData}
                displayConfig={displayConfig}
                chartStyles={chartStyles}
            />

            {renderBreakout()}
        </Page>

    );
};

export default ChartPageRecurringRevenue;