const reports = [{
    id: 'global-revenue',
    menuTitle: "Global Revenue",
    adminOnly: false,
    items: [{
        id: 'net-revenue',
        menuTitle: 'Net Revenue',
    },{
        id: 'stripe-fees',
        menuTitle: 'Stripe Fees',
    },{
        id: 'refunds',
        menuTitle: 'Refunds',
    }]
},{
    id: 'revenue',
    menuTitle: "Revenue",
    adminOnly: false,
    items: [{
        id: 'mrr',
        menuTitle: 'MRR',
    },{
        id: 'arr',
        menuTitle: 'ARR',
    },{
        id: 'nrr',
        menuTitle: 'NRR',
    },{
        id: 'mrr-breakout',
        menuTitle: 'MRR Breakout',
    },{
        id: 'mrr-growth-rate',
        menuTitle: 'MRR Growth Rate',
    },{
        id: 'arpu',
        menuTitle: 'ARPU',
    },{
        id: 'churn-rate',
        menuTitle: 'Churn Rate',
    },{
        id: 'revenue-churn',
        menuTitle: 'Revenue Churn',
    },{
        id: 'ltv',
        menuTitle: 'LTV',
    },{
        id: 'addons',
        menuTitle: 'Addons',
    },
    // {
    //     id: 'commissions',
    //     menuTitle: 'Commissions',
    // }
]
},{
    id: 'activity',
    menuTitle: "Customer Activity",
    adminOnly: false,
    items: [{
        id: 'active-customers',
        menuTitle: 'Active Customers',
    },{
        id: 'cel',
        menuTitle: 'CEL',
    },{
        id: 'new-customers',
        menuTitle: 'New Customers',
    },{
        id: 'active-subscriptions',
        menuTitle: 'Active Subscriptions',
    },{
        id: 'new-subscriptions',
        menuTitle: 'New Subscriptions',
    },{
        id: 'customer-report',
        menuTitle: 'Customer Report',
    }]
},

// {
//     id: 'infrastructure',
//     menuTitle: "Infrastructure",
//     adminOnly: false,
//     items: [{
//         id: 'gcp-grouped',
//         menuTitle: 'GCP Cost Groups',
//     }]
// },

{
    id: 'fraud',
    menuTitle: "Fraud",
    adminOnly: false,
    visibleToAll: true,
    items: [{
        id: 'fraud-detection',
        menuTitle: 'Fraud Detection',
    }]
},{
    id: 'billing-accounts',
    menuTitle: "Billing & Accounts",
    adminOnly: false,
    items: [{
        id: 'failed-payments',
        menuTitle: 'Failed Payments',
    },{
        id: 'billing-report',
        menuTitle: 'Billing Report',
    },{
        id: 'key-accounts',
        menuTitle: 'Key Accounts',
    }]
},{
    id: 'admin',
    menuTitle: "Administration",
    adminOnly: true,
    items: [{
        id: 'user-management',
        menuTitle: 'User Management',
    }]
}]

export default reports