export default function(searchParams) : {
    filter: Boolean,
    countries: string[],
    regions: string[]
} {
    const countries = searchParams.getAll('country')
    const regions = searchParams.getAll('region')

    if(countries.length === 0 && regions.length === 0) {
        return {
            filter: false,
            countries: [],
            regions: []
        }
    }

    return {
        filter: true,
        countries,
        regions
    }

}