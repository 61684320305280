import React from "react";
import {ConfigProvider, DatePicker} from "antd";
import dayjs, {Dayjs} from "../utils/dayjs";
import {get} from "lodash";
import locale from 'antd/es/locale/en_US'

const dateRangePresets = (lastUpdate) : {
    label: string;
    value: [Dayjs, Dayjs];
}[] => [
    { label: 'Last 7 Days', value: [lastUpdate.add(-7, 'd'), lastUpdate.subtract(1, 'day')] },
    { label: 'Last 14 Days', value: [lastUpdate.add(-14, 'd'), lastUpdate.subtract(1, 'day')] },
    { label: 'Last 30 Days', value: [lastUpdate.add(-30, 'd'), lastUpdate.subtract(1, 'day')] },
    { label: 'This Month', value: [lastUpdate.startOf('month'), lastUpdate.subtract(1, 'day')] },
    { label: 'Last Month', value: [lastUpdate.subtract(1, 'month').startOf('month'), lastUpdate.subtract(1, 'month').endOf('month')] },
    { label: 'Last 60 Days', value: [lastUpdate.add(-60, 'd'), lastUpdate.subtract(1, 'day')] },
    { label: 'Last 90 Days', value: [lastUpdate.add(-90, 'd'), lastUpdate.subtract(1, 'day')] },
    { label: 'Last 1 Year', value: [lastUpdate.add(-1, 'y').startOf('year'), lastUpdate.subtract(1, 'day')] },
    { label: 'Last 3 Year', value: [lastUpdate.add(-3, 'y').startOf('year'), lastUpdate.subtract(1, 'day')] },
    { label: 'Last 5 Year', value: [lastUpdate.add(-5, 'y').startOf('year'), lastUpdate.subtract(1, 'day')] },
]

const dateRangeChangeHandler = (dates, dateFilter, setDateFilter, searchParams, setSearchParams) => {
    const start = get(dates, '[0]', dateFilter[0])
    const end = get(dates, '[1]', dateFilter[1])
    setDateFilter([start, end ])
    setSearchParams({
        ...Object.fromEntries([...searchParams]),
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD')
    })

}

const DateRangePicker: React.FC<{ filter, setFilter,lastUpdate, additionalPresets?, presets?, searchParams, setSearchParams}> = ({ filter, setFilter, presets, additionalPresets = [], lastUpdate, searchParams, setSearchParams}) => {
    dayjs.updateLocale("en", {
        weekStart: 1
    });

    return <ConfigProvider locale={locale}><DatePicker.RangePicker
        value={filter}
        presets={presets ? presets : [
            ...additionalPresets,
            ...dateRangePresets(lastUpdate)
        ]}
        onChange={(dates) => dateRangeChangeHandler(dates, filter, setFilter, searchParams, setSearchParams)}/></ConfigProvider>

}

export default DateRangePicker
