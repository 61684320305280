import formatValue from "../utils/formatValue";
import {groupBy, mean, sum} from "lodash";
import ValueFormat from "../types/ValueFormats";
import React from "react";
import dayjs, {Dayjs} from "../utils/dayjs";
import PeriodDifference from "./PeriodDifference";
import chartTheme from "../utils/chartTheme";
import ChartData from "../types/ChartData";
import numeral from "../utils/numeral";
import {DownCircleTwoTone, MinusCircleTwoTone, UpCircleTwoTone} from "@ant-design/icons";
import Label from "./Label";

const ChartSummary: React.FC<{
    data: ChartData
    displayConfig: {
        valueFormat: ValueFormat
        summaryDisplay: Boolean
        valueModifier?: (value : number) => number
    }
}> = ({
    data,
    displayConfig
}) => {
    const titleMap = {
        sum: 'Total over period',
        average:  'Average over period',
        state: 'At period end'
    }

    const comparisonTitleMap = {
        previousPeriod: 'from previous period',
        inPeriod: 'in period'
    }


    return <div>
        <Label>{titleMap[data.summary.aggregation]}</Label>
        <div style={{ color: '#5333ed', marginBottom:6, fontSize: 32, fontWeight: 700}}>
            {numeral(data.summary.currentValue).format(displayConfig.valueFormat)}
        </div>
        <div>
            <PeriodDifference first={data.summary.previousValue} last={data.summary.currentValue} prepend={comparisonTitleMap[data.summary.comparisonType]} />
        </div>
    </div>


}

export default ChartSummary