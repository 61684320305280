import React from "react";
import dayjs from "../utils/dayjs";
import numeral from "../utils/numeral";
import chartTheme from "../utils/chartTheme";
import {sortBy, uniq} from "lodash";
import ChartData from "../types/ChartData";

const StackChartFlyout: React.FC<{x?, y?, datum?, format, dateTickFormat, dateGroupBy, data: ChartData, active?, activePoints?}> = ({ x, y, datum ,format, dateGroupBy, dateTickFormat, data, activePoints}) => {
    const currentData = data.dataPoints.filter(item => item.x === datum.x)[0]
    const theme = chartTheme()
    const displayType = (dataType, i) => {
        return <div style={{ display: 'flex', alignItems:'center', marginTop:8}}>
            <div style={{ marginRight:4, width: 12, height:12, borderRadius:24, background: theme.group.colorScale[i] }}></div>
            <span style={{ fontSize: 10}}>{data.stackData.stacks.filter(stack => stack.value === dataType)[0].label}: </span>
            <span style={{ marginLeft: 'auto', fontSize: 10, fontWeight: 700}}> {numeral(currentData.stacks.filter(item => item.stack === dataType)[0].y).format(format)}</span>
        </div>
    }

     return <g style={{ pointerEvents: "none" }}>
                <foreignObject
                    x={x - 150}
                    y={y - 98}
                    width="160"
                    height="10"
                    style={{ overflow: "visible" }}
                >
                    <div style={{
                        padding:12,
                        textAlign:'center',
                        background:'#fff',
                        boxShadow:'0 1px 2px rgba(96,120,255,.1)',
                        border: '1px solid #f5f5f7',

                    }}>
                        <div
                            style={{
                                fontSize: 10,
                                marginBottom:4,
                                fontWeight:600,
                                color: '#444',
                            }}
                        >{
                            dateGroupBy === 'week'
                                ? <div>{dayjs(datum.x).subtract(6, 'days').format(dateTickFormat)} - {dayjs(datum.x).format(dateTickFormat)}</div>
                                : dayjs(datum.x).format(dateTickFormat)


                        }</div>

                        {sortBy(currentData.stacks, 'stack').map( (stack, i) => {
                            return <div key={`flyoutgroup-${stack.stack}`}>
                                {displayType(stack.stack, i)}
                            </div>

                        })}


                    </div>

                </foreignObject>
            </g>
}

export default StackChartFlyout
