import React from "react";
import {Collapse, Typography} from "antd";
import DocsAccountHistory from "./DocsAccountHistory";
import DocsPrices from "./DocsPrices";
import {
    ArrowRightOutlined,
    BookTwoTone,
    CalculatorTwoTone,
    DatabaseTwoTone,
    InfoCircleTwoTone
} from "@ant-design/icons";
import DocsAccountActivity from "./DocsAccountActivity";
import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';

const DocsRecurringRevenue: React.FC = (props) => {
    const { Text, Paragraph, Title } = Typography;

    return <div>
        <Paragraph>
            <Title level={5} style={{marginTop:0}}>
                <CalculatorTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Formula
            </Title>
        </Paragraph>

        <BlockMath math="\footnotesize \text{For all active subscription items on a given day}"/>
        <BlockMath math="\large
        \sum_{} quantity \times price \times  \begin{cases}
            \enspace \large 1 \footnotesize \text{ if interval is  month } \\
            \enspace \large 1/12 \footnotesize \text{ if interval is year } \\
            \enspace \large 30.436875 \footnotesize \text{ if interval is day } \\
        \end{cases} \times \begin{cases}
            \enspace \large 1 \footnotesize \text{ if report is MRR } \\
            \enspace \large 12 \footnotesize \text{ if report is ARR } \\
        \end{cases}


        "/>
        <Paragraph>
            <Title level={5}>
                <DatabaseTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Data Source
            </Title>
        </Paragraph>

        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="mrrByProductGroup" key="dependency">
                <div style={{display: 'flex', alignItems:'center', gap: 60}}>
                    <div>
                        <ul>
                            <li>Stripe Events
                                <ul>
                                    <li><Text code>accountHistory</Text></li>
                                    <li><Text code>prices</Text></li>
                                    <li><Text code>customers</Text></li>
                                    <li><Text code>customerCountry</Text></li>
                                </ul>
                            </li>
                            <li>
                                Stripe Sigma
                                <ul>
                                    <li><Text code>usageRecords</Text></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ArrowRightOutlined style={{ fontSize: 30}} />
                    </div>
                    <div>
                        <Text code>mrrByProductGroup</Text>
                    </div>

                </div>
            </Collapse.Panel>
        </Collapse>


        <Paragraph>
            <Title level={5}>
                <InfoCircleTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Details
            </Title>
        </Paragraph>

        <Paragraph>
            MRR represents the total monthly revenue our active subscription items generate. Each subscription item
            has a <Text code>quantity</Text>, a <Text code>price</Text> and an <Text code>interval</Text> associated with it.
        </Paragraph>

        <Paragraph style={{marginBottom:0}}><strong>Quantity</strong></Paragraph>
        <Paragraph>
            For subscription based items the quantity is the amount of the given service a customer purchases. For example
            1 Pro plan or 4 Hourly Backup Addon. For usage based items this is the usage in usage units. For example
            1,200 Build Time or 86,400 App H1 which represents 1,200 seconds (20 minutes) of build time used and 86,400 seconds
            (24 hours) of App H1 usage.
        </Paragraph>

        <Paragraph style={{marginBottom:0}}><strong>Price</strong></Paragraph>
        <Paragraph>
            The price represents the price per unit for each subscription item.
        </Paragraph>

        <Paragraph style={{marginBottom:0}}><strong>Interval</strong></Paragraph>
        <Paragraph>
            The interval represents the frequency of billing applied to the subscription item. Billing frequency for usage
            based items is set to monthly but actual billing may happen if a customer reaches certain thresholds. Regardless
            of the billing mechanism, we collect daily usage data due which - from an MRR perspective - is equivalent to
            a daily interval. We use 30.436875 as the multiplier for daily intervals as that is the average number of
            days in a month.
        </Paragraph>

        <Paragraph>
            <Title level={5}>
                <BookTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Further Reading
            </Title>
        </Paragraph>


        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="Understanding Account History" key="account-history">
                <DocsAccountHistory />
            </Collapse.Panel>

            <Collapse.Panel header="Understanding Prices" key="prices">
                <DocsPrices />
            </Collapse.Panel>

            <Collapse.Panel header="Understanding Account Activity" key="account-activity">
                <DocsAccountActivity />
            </Collapse.Panel>

        </Collapse>


    </div>

}



export default DocsRecurringRevenue