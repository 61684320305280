import React from "react";
import {DownCircleTwoTone, MinusCircleTwoTone, PieChartOutlined, UpCircleTwoTone} from "@ant-design/icons";
import formatValue from "../utils/formatValue";
import ValueFormat from "../types/ValueFormats";

const PeriodDifference: React.FC<{ first, last, prepend?, lineBreak? }> = ({ first, last, prepend='within period', lineBreak = false}) => {

    const difference = last - first

    let color = '#bbb'
    let icon = <MinusCircleTwoTone twoToneColor={color} />
    if(difference > 0) {
        color = "rgba(18,196,87,1)"
        icon =  <UpCircleTwoTone twoToneColor={color} />
    } else if(difference < 0) {
        color = "rgba(232,76,133,1)"
        icon =  <DownCircleTwoTone twoToneColor={color} />
    }

    return <div>
        {icon}
        <span style={{ paddingLeft: 4, color, fontWeight: 'bold' }}>{
            first === 0 ? '∞' :
            formatValue(Math.abs(difference) / first, ValueFormat.percentage)
        }</span>
        <span style={{ paddingLeft: 4, color: '#999'}}>
            {lineBreak ? <br /> : null}
        {prepend}
        </span>
    </div>

}

export default PeriodDifference