import React, {useState} from 'react';
import {gql, useQuery} from '@apollo/client';
import {Input, Table} from 'antd';
import Page from '../layouts/Page';
import {useSearchParams} from 'react-router-dom'
import PageLoading from '../components/PageLoading';
import formatValue from "../utils/formatValue";
import ValueFormat from "../types/ValueFormats";
import {max, sortBy, sum, uniq} from "lodash";

const GET_DATA = gql`
query data(
        $individualThreshold: Int
        $combinedThreshold: Int
) {    
    getAccountHistoryForKeyAccounts(
        individualThreshold: $individualThreshold
        combinedThreshold: $combinedThreshold
    ) {
        id
        date
        customer
        subscription
        customerDescription
        customerCreated
        customerEmail
        quantity
        price
        priceName
        amount
        serviceGroup
        productGroup
        serviceType
        productType
        interval
        mrr
        customerCompany
    }
     
}
`;

const BillingReport: React.FC<{ getSystemProcessing }> = ({ getSystemProcessing }) => {
    /* Get Base Properties */
    const lastUpdate = getSystemProcessing.filter(item => item.processor === 'accountActivity')[0].date
    const pageTitle = 'Key Accounts'
    let [searchParams, setSearchParams] = useSearchParams();
    /* Filter States */

    const [individualThresholdFilter, setIndividualThresholdFilter] = useState<number>(1651)
    const [combinedThresholdFilter, setCombinedThresholdFilter] = useState<number>(5000)

    const handleIndividualThresholdChange = (individualThreshold) => {
        setIndividualThresholdFilter(parseInt(individualThreshold))
        setSearchParams({
            ...Object.fromEntries([...searchParams]),
            individualThreshold
        })
    }

    const handleCombinedThresholdFilter = (combinedThresholdFilter) => {
        setCombinedThresholdFilter(parseInt(combinedThresholdFilter))
        setSearchParams({
            ...Object.fromEntries([...searchParams]),
            combinedThresholdFilter
        })
    }



    /* Filters */
    const filters: {
        label: string,
        filter: React.FC
    }[] = [{
        label: 'Individual Threshold',
        filter: () => {
            return <div>
                <Input.Search defaultValue={individualThresholdFilter} enterButton onSearch={handleIndividualThresholdChange}/>
            </div>
        }
    },{
        label: 'Combined Threshold',
        filter: () => {
            return <div>
                <Input.Search defaultValue={combinedThresholdFilter} enterButton onSearch={handleCombinedThresholdFilter} />
            </div>
        }
    }]

    /* Retrieve Data */
    const { data, loading, refetch } = useQuery(GET_DATA, {variables: {
        individualThreshold:individualThresholdFilter,
        combinedThreshold:combinedThresholdFilter
    }})



    /* Handle Loading State */
    if(loading) {
        return <PageLoading title={pageTitle} lastUpdate={lastUpdate} />
    }
    /* Page Logic */
    const columns = [{
        title: 'Customer',
        render: (_, item) => {
            return <div>
                <div style={{fontSize: 16}}> <a target="_blank" href={`https://dashboard.stripe.com/customers/${item.customer}`}>{item.customerDescription}</a></div>
                <div style={{ fontFamily:"monospace", fontSize:12, color: '#777'}}>{item.customer}</div>
                <div style={{ fontSize:12, color: '#777'}}>{item.customerEmail}</div>
            </div>
        }
    },{
        title: 'Total MRR',
        key: 'totalMrr',
        dataIndex: 'totalMrr',
        width:120,
        render: (_, customer) => {
            return <div>{formatValue(sum(customer.items.map(item => item.mrr)), ValueFormat.currency)}</div>
        }
    },{
        title: 'Largest Item',
        key: 'largestItem',
        dataIndex: 'largestItem',
        width:120,
        render: (largestItem, customer) => {
            return <div>{formatValue(largestItem, ValueFormat.currency)}</div>
        }
    },{
        title: 'Details',
        render: (_, customer) => {
            return <div>{customer.items.map(item => `${item.quantity}x ${item.priceName}`).join(', ')}</div>

        }
    }];

    const customers = uniq(data.getAccountHistoryForKeyAccounts.map(item => item.customer))
    const dataSource = sortBy(customers.map(customer => {
        const items = data.getAccountHistoryForKeyAccounts.filter(item => item.customer === customer)

        return {
            customer,
            customerEmail: items[0].customerEmail,
            customerDescription: items[0].customerDescription,
            customerCreated: items[0].customerCreated,
            items,
            largestItem: max(items.map(item => item.mrr)),
            totalMrr: sum(items.map(item => item.mrr))
        }

    }), ['totalMrr']).reverse()



    return (
        <Page frame={true} title={pageTitle} lastUpdate={lastUpdate} filters={filters}>
            <div>
                <div style={{ fontSize:12, margin: "0 0 10px 0", textTransform:'uppercase'}}>{dataSource.length} Items</div>
                <Table pagination={false} dataSource={dataSource} columns={columns} rowKey={(item : any) => item.customer} />

            </div>
        </Page>

    );
};

export default BillingReport;