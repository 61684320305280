import DateGroupBy from "../types/DateGroupBy";

export default function(searchParams, defaultDateGroup) : DateGroupBy {
    const dateGroup = searchParams.get('dateGroupBy') || ''
    if(Object.values(DateGroupBy).includes(dateGroup)) {
        return DateGroupBy[dateGroup]
    }

    return defaultDateGroup

}