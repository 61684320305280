import React, {useState} from "react";
import {Affix, Button, Drawer} from "antd";
import styled from 'styled-components';
import {QuestionCircleTwoTone, QuestionOutlined} from "@ant-design/icons";
import {useSearchParams} from "react-router-dom";

const Page: React.FC<{ children: React.ReactNode , title, lastUpdate?, filters?, frame?, docs? }> = ({ children, title, lastUpdate, filters, frame, docs }) => {
    const FilterLabels = styled.div({ textTransform: 'uppercase', marginBottom:5, color:'#aaa', fontSize:12, fontWeight:600})
    let [searchParams, setSearchParams] = useSearchParams();
    const [drawerState, setDrawerState] = useState(() => {
        const searchParamsState = searchParams.get('help')
        if(!searchParamsState) {
            return false
        }

        return searchParamsState === 'yes' ? true : false
    });

    const showDrawer = () => {
        setSearchParams({
            ...Object.fromEntries([...searchParams]),
            help: 'yes'
        })
        setDrawerState(true)
    };

    const onClose = () => {
        setDrawerState(false)
        setSearchParams({
            ...Object.fromEntries([...searchParams]),
            help: 'no'
        })
    };

    const renderFilters = () => {
        return <Affix>
            <div style={{ rowGap: 10, flexWrap: 'wrap', display:'flex', alignItems: 'center', borderBottom: '1px solid #eee', background: '#fff', borderTopLeftRadius: 12, borderTopRightRadius: 12, padding: '18px 24px 18px 0'}}>
                {filters.map(filter => {
                    return <div style={{marginLeft: 22}} key={filter.label}>
                        <div>
                            <FilterLabels>{filter.label}</FilterLabels>
                            <div>{filter.filter({}, {})}</div>
                        </div>
                    </div>
                })}
            </div>
        </Affix>

    }

    const frameStyle = {  margin: '0 24px', borderRadius: 12, boxShadow: '0 2px 10px 0 rgba(51, 51, 79, .02)' }
    const frameContentStyle = {}

    if(frame) {
        frameContentStyle['padding'] = "18px 24px"
        frameStyle['background'] = '#fff'
    }

    const renderLastUpdate = () => <div style={{lineHeight:1, marginTop: 6, fontSize:12, color:'#777777'}}>
        Updated Through: <span style={{fontWeight:600}}>{lastUpdate.subtract(1, 'day').format('YYYY-MM-DD')} UTC</span>
    </div>


    return <div>

        <div style={{ margin:'0 24px', padding:0, height:100, background: 'transparent'}}>
            <div style={{ display:'flex',height:100, alignItems:'center'}}>

                <div>
                    <div style={{fontSize:24, fontWeight:600, lineHeight:1}}>
                        <div style={{display: 'flex', alignItems:'center'}}>
                            {title}
                            {docs && docs?.element ? <Button onClick={() => showDrawer()} style={{ marginLeft: 10}} type="default" size={'small'} shape="circle" icon={<QuestionOutlined style={{ fontSize: 12}} />} /> : null }
                        </div>

                    </div>

                    {lastUpdate ? renderLastUpdate() : null}

                </div>

            </div>

        </div>

        <div style={frameStyle}>
            {filters ? renderFilters() : null}
            <div style={frameContentStyle}>
                {children}
            </div>
        </div>

        {docs?.element ? <Drawer width={900} title={docs?.title ? docs.title : "About This Report"} placement="right" onClose={onClose} open={drawerState}>
            {docs.element}
        </Drawer>
            : null}


    </div>
}

export default Page