import React from "react";
import {Select} from "antd";
import DateGroupBy from "../types/DateGroupBy";

const dateGroupByChangeHandler = (aggregation, aggregationFilter, setAggregationFilter, searchParams, setSearchParams) => {
    const newAggregation = aggregation || aggregationFilter
    setAggregationFilter(newAggregation)
    setSearchParams({
        ...Object.fromEntries([...searchParams]),
        dateGroupBy: newAggregation
    })

}

const DateGroupBySelector: React.FC<{ filter, setFilter, searchParams, setSearchParams, types? }> = ({ filter, setFilter, searchParams, setSearchParams, types = [DateGroupBy.day, DateGroupBy.month, DateGroupBy.week, DateGroupBy.year]}) => {
    const options = [
        {value: 'day', label: 'Day'},
        {value: 'week', label: 'Week'},
        {value: 'month', label: 'Month'},
        {value: 'year', label: 'Year'},
    ]
    return <Select
        value={filter}
        style={{width: 120}}
        onChange={(aggregation) => dateGroupByChangeHandler(aggregation, filter, setFilter, searchParams, setSearchParams)}
        options={options.filter(item => types.includes(item.value))}
    />

}

export default DateGroupBySelector
