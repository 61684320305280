import React from 'react';
import {Outlet} from "react-router-dom";

interface MyProps {}

const SingleCentered: React.FC<MyProps> = (props) => {

    return <div>
        <Outlet />
    </div>
};

export default SingleCentered;