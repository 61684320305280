import React from "react";
import {Collapse, Table, Typography} from "antd";
import {
    ArrowRightOutlined, BookTwoTone,
    CalculatorTwoTone,
    DatabaseTwoTone,
    InfoCircleTwoTone
} from "@ant-design/icons";
import { BlockMath } from 'react-katex';
import {Link} from "react-router-dom";

const DocsNetRevenue: React.FC = (props) => {
    const { Text, Paragraph, Title } = Typography;

    return <div>


        <Paragraph>
            <Title level={5} style={{marginTop:0}}>
                <CalculatorTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Formula
            </Title>
        </Paragraph>

        <BlockMath math="\large
        \frac{\text{MRR}}{\text{Active Customers}}
        "/>
        <Paragraph>
            <Title level={5}>
                <DatabaseTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Data Source
            </Title>
        </Paragraph>

        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="arpu" key="dependency">
                <div style={{display: 'flex', alignItems:'center', gap: 60}}>
                    <div>
                        <ul>
                            <li>Stripe Events
                                <ul>
                                    <li><Text code>accountHistory</Text></li>
                                    <li><Text code>prices</Text></li>
                                    <li><Text code>customers</Text></li>
                                    <li><Text code>customerCountry</Text></li>
                                </ul>
                            </li>
                            <li>
                                Stripe Sigma
                                <ul>
                                    <li><Text code>usageRecords</Text></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ArrowRightOutlined style={{ fontSize: 30}} />
                    </div>
                    <div>
                        <ul style={{padding:0}}>
                            <li><Text code>mrrByProductGroup</Text></li>
                            <li><Text code>activeCustomersByProductGroup</Text></li>
                        </ul>
                    </div>

                </div>
            </Collapse.Panel>
        </Collapse>


        <Paragraph>
            <Title level={5}>
                <InfoCircleTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Details
            </Title>
        </Paragraph>

        <Paragraph>
            Note that due to the way items need to be aggregated, stacks will <strong>not</strong> add up to the aggregate
            value. In the above example for Canada the aggregated ARPU is weighted, so it will be $106.44. Grouping will give
            you the individual ARPU values (106.507 for WordPress and 20 for PaaS).
        </Paragraph>

        <Paragraph>
            <Title level={5}>
                <BookTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Further Reading
            </Title>
        </Paragraph>

        <Paragraph>
            <ul>
                <li>
                    <Link to="/revenue/mrr?help=yes">MRR</Link>
                </li>
                <li>
                    <Link to="/activity/active-customers?help=yes">Active Customers</Link>
                </li>
            </ul>
        </Paragraph>

        </div>

}

export default DocsNetRevenue