import React from "react";
import {Collapse, Table, Typography} from "antd";

const DocsPrices: React.FC = (props) => {
    const { Text, Paragraph } = Typography;

    const tableData = [{
        id: 1,
        amount: '$200',
        interval: 'month',
        name: 'Business 1',
        serviceGroup: 'wp',
        productGroup: 'wp',
        serviceType: 'base',
        productType: 'wp',
        wire: 'no',
        usageBased: 'no'
    },{
        id: 2,
        amount: '$0.032',
        interval: 'month',
        name: 'App S1',
        serviceGroup: 'paas',
        productGroup: 'app',
        serviceType: 'base',
        productType: 'app',
        wire: 'no',
        usageBased: 'yes'
    }]

    const columns = [
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Interval',
            dataIndex: 'interval',
            key: 'interval',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'serviceGroup',
            dataIndex: 'serviceGroup',
            key: 'serviceGroup',
        },
        {
            title: 'productGroup',
            dataIndex: 'productGroup',
            key: 'productGroup',
        },
        {
            title: 'serviceType',
            dataIndex: 'serviceType',
            key: 'serviceTypem',
        },
        {
            title: 'productType',
            dataIndex: 'productType',
            key: 'productType',
        },
        {
            title: 'Wire',
            dataIndex: 'wire',
            key: 'wire',
        },
        {
            title: 'Usage',
            dataIndex: 'usageBased',
            key: 'usageBased',
        },
    ];


    return <div>


        <Paragraph>
            As you've seen above, each entry in the Account History table has a price identified associated with it.
            We also maintain a <Text code>prices</Text> table that shows details about each price.
        </Paragraph>

        <Paragraph>
            <Table size={'small'} rowKey={(record) => record.id} pagination={false} dataSource={tableData} columns={columns} />
        </Paragraph>

        <Paragraph>
            Rows in this table show us the price of the service, it's name, interval, categorization (which we use to separate
            out our various businesses), if the price is a wire transfer price and if it is usage based or not.
        </Paragraph>

        <Paragraph>
            Understanding categorization will be important to understand how our metrics are segmented. There are four groups
            of categories (taxonomies) we use:
        </Paragraph>
            <ul>
                <li><strong>Service Group</strong> which will be either <Text code>wp</Text> or <Text code>paas</Text>
                and it is used to separate out the two main branches of our services</li>
                <li><strong>Product Group</strong> drills down into service groups, categorizing them further.
                    It will be <Text code>wp</Text> for all WordPress based services and will
                    can be <Text code>db</Text> (for Database services), <Text code>app</Text> for application hosting,
                    <Text code>build</Text> for build time used and <Text code>bw</Text> for bandwidth used.
                    </li>
                <li><strong>Service Type</strong> is used to differentiate between services that are at the core
                    of our hosting and addons that are optional extras customers can purchase. The categories used are
                    <Text code>base</Text> and <Text code>addon</Text> respectively</li>
                <li><strong>Product Type</strong> is essentially a technical taxonomy which can be derived from the previous
                three, but it was useful to store it in the table. The value of this taxonomy will be the same as
                the value of the product group for items that have <Text code>base</Text> as their service type and will be
                the actual price identifier for addons. </li>


            </ul>
        <Paragraph>
            The key point is that we are differentiating our metrics by the large PaaS - WP grouping but also by sub-products
            within.
        </Paragraph>



    </div>

}

export default DocsPrices