import numeral from 'numeral'

numeral.register('locale', 'us', {
    delimiters: {
        thousands: ',',
        decimal: '.'
    },
    abbreviations: {
        thousand: 'K',
        million: 'M',
        billion: 'B',
        trillion: 'T'
    },
    currency: {
        symbol: '$'
    }
});

numeral.locale('us');


export default numeral