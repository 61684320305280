import React from "react";
import {Collapse, Typography} from "antd";
import {Link} from "react-router-dom";
import { BlockMath } from 'react-katex';
import {
    ArrowRightOutlined,
    BookTwoTone,
    CalculatorTwoTone,
    DatabaseTwoTone
} from "@ant-design/icons";

const DocsLtv: React.FC = (props) => {
    const { Text, Paragraph, Title } = Typography;


    return <div>


        <Paragraph>
            <Title level={5} style={{marginTop:0}}>
                <CalculatorTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Formula
            </Title>
        </Paragraph>

        <BlockMath math="\large
        \frac{\text{ARPU}}{\text{Churn Rate}}
        "/>


        <Paragraph>
            <Title level={5}>
                <DatabaseTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Data Source
            </Title>
        </Paragraph>

        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="ltv" key="dependency">
                <div style={{display: 'flex', alignItems:'center', gap: 60}}>
                    <div>
                        <ul>
                            <li>Stripe Events
                                <ul>
                                    <li><Text code>accountHistory</Text></li>
                                    <li><Text code>prices</Text></li>
                                    <li><Text code>customers</Text></li>
                                    <li><Text code>customerCountry</Text></li>
                                </ul>
                            </li>
                            <li>
                                Stripe Sigma
                                <ul>
                                    <li><Text code>usageRecords</Text></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ArrowRightOutlined style={{ fontSize: 30}} />
                    </div>
                    <div>
                        <ul style={{padding:0}}>
                            <li><Text code>mrrByProductGroup</Text></li>
                            <li><Text code>activeCustomersByProductGroup</Text></li>
                            <li><Text code>accountActivity</Text></li>
                            <li><Text code>activeCustomers</Text></li>
                        </ul>
                    </div>

                </div>
            </Collapse.Panel>
        </Collapse>



        <Paragraph>
            <Title level={5}>
                <BookTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Further Reading
            </Title>
        </Paragraph>

        <Paragraph>
            <ul>
                <li><Link to={'/revenue/arpu'}>ARPU</Link></li>
                <li><Link to={'/revenue/churn-rate'}>Churn Rate</Link></li>
            </ul>
        </Paragraph>


        </div>

}

export default DocsLtv