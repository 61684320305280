import React from "react";
import {Collapse, Table, Typography} from "antd";

const DocsAccountHistory: React.FC = (props) => {
    const { Text, Paragraph } = Typography;

    const tableData = [{
        id: 1,
        date: '2023-04-20',
        customer: 'cus_1',
        quantity: 1,
        price: 'price_1',
        subscription_item: 'si_1',
        subscription: 'sub_1',
        country: 'US'
    },{
        id: 2,
        date: '2023-04-20',
        customer: 'cus_4',
        quantity: '960',
        price: 'price_2',
        subscription_item: 'si_2',
        subscription: 'sub_2',
        country: 'CA'
    }]

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Subscription',
            dataIndex: 'subscription',
            key: 'subscription',
        },
        {
            title: 'Item',
            dataIndex: 'subscription_item',
            key: 'subscription_item',
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
        },
    ];


    return <div>


        <Paragraph>
            Account History forms the basis of many reports. It is a table that contains the full subscription composition
            of all our customers everyday. Two rows from the table might look something like this.
        </Paragraph>

        <Paragraph>
            <Table size={'small'} rowKey={(record) => record.id} pagination={false} dataSource={tableData} columns={columns} />
        </Paragraph>

        <Paragraph>
            The first row represents a customer <Text code>#cus_1</Text> who is using 1 quantity of one of our services on the given date.
            The service is represented by <Text code>price_1</Text> which we can look up in a different table and my
            be something like a Business 1 Plan at $100 / month. The country reflects the billing country of the customer
            on the given day.
        </Paragraph>

        <Paragraph>
            This table essentially shows the entire plan composition of our customer base for each day. If you filter the
            table to show you <Text code>2023-04-20</Text> and <Text code>cus_1</Text> you would arrive at all the
            products that customer is using along with the quantity and price.
        </Paragraph>

        <Paragraph>
            This table is extremely useful because it allows us to calculate many of our metrics. For example, we can
            calculate the MRR of each individual row by and then add each row's MRR for a given day to arrive at the
            MRR for the day.
        </Paragraph>

    </div>

}

export default DocsAccountHistory