import React from "react";
import {PieChartOutlined} from "@ant-design/icons";

const EmptyChart: React.FC = (props) => {
    return <div style={{ textAlign: 'center', padding:'40px 0' }}>
        <PieChartOutlined style={{ fontSize: 80, color: '#eee' }} />
        <div style={{ marginTop:20, fontSize: 20, color: '#ddd', textTransform:'uppercase' }}>No data for the given parameters</div>
    </div>

}

export default EmptyChart