import React, {useContext} from "react";
import {Select, TreeSelect} from "antd";
import countryList from "../utils/countryList";
import regionList from "../utils/regionList";
import {AuthContext} from "./AuthContext";
import getAllowedCountriesForUser from "../utils/getAllowedCountriesForUser";
import getAllowedRegionsForUser from "../utils/getAllowedRegionsForUser";

const countryChangeHandler = (selectorState, filter, setFilter, searchParams, setSearchParams) => {
    if(selectorState) {
        const countries = selectorState.filter(item => item.startsWith('country-')).map(item => item.replace('country-', ''))
        const regions = selectorState.filter(item => item.startsWith('region-')).map(item => item.replace('region-', ''))

        if(selectorState.includes('all')) {
            setFilter(selectorState)
            searchParams.delete('country')
            searchParams.delete('region')
            setSearchParams(searchParams)
        } else {
            setFilter({
                filter:true,
                countries,
                regions
            })


            const newSearchParams = {
                ...Object.fromEntries([...searchParams]),
            }

            if(countries.length > 0) {
                newSearchParams['country'] = countries
            }

            if(regions.length > 0) {
                newSearchParams['region'] = regions
            }

            setSearchParams(newSearchParams)
        }
    }

}

const countryClearHandler = (searchParams, setSearchParams, setFilter) => {
    searchParams.delete('country')
    searchParams.delete('region')
    setFilter({
        filter: false,
        countries: [],
        regions: []
    })
    setSearchParams(searchParams)

}

const LocationSelector: React.FC<{ filter, setFilter, searchParams, setSearchParams}> = ({ filter, setFilter, searchParams, setSearchParams}) => {
    const {user} = useContext(AuthContext)


    const regions = getAllowedRegionsForUser(user.permissions)
    const countries = getAllowedCountriesForUser(user.permissions)


    const treeData = [

        {
            value: 'regions',
            title: 'Regions',
            selectable: false,
            children: regions
        },
        {
            value: 'countries',
            title: 'Countries',
            selectable: false,
            children: countries
        },

    ];

    const value = [
        ...filter.countries.map(item => `country-${item}`),
        ...filter.regions.map(item => `region-${item}`),
    ]

    return <TreeSelect
        multiple={true}
        showSearch
        treeLine
        style={{ width: 200 }}
        value={value}
        dropdownStyle={{ maxHeight: 550, overflow: 'auto' }}
        placeholder="Please select"
        onClear={() => countryClearHandler(searchParams, setSearchParams, setFilter)}
        allowClear
        treeNodeFilterProp={'title'}
        onChange={(selectorState) => countryChangeHandler(selectorState, filter, setFilter, searchParams, setSearchParams)}
        treeData={treeData}
    />


}

export default LocationSelector
