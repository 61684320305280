import React from "react";
import {Collapse, Table, Typography} from "antd";
import {
    ArrowRightOutlined,
    BookTwoTone,
    CalculatorTwoTone,
    DatabaseTwoTone,
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import { BlockMath } from 'react-katex';

const DocsMrrGrowthRate: React.FC = (props) => {
    const { Text, Paragraph, Title } = Typography;

    return <div>


        <Paragraph>
            <Title level={5} style={{marginTop:0}}>
                <CalculatorTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Formula
            </Title>
        </Paragraph>

        <BlockMath math="\large
        \Bigg(\frac{\text{MRR on given date}}{\text{MRR 30 days ago}} - 1 \Bigg) \times 100
        "/>
        <Paragraph>
            <Title level={5}>
                <DatabaseTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Data Source
            </Title>
        </Paragraph>

        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="mrrByProductGroup" key="dependency">
                <div style={{display: 'flex', alignItems:'center', gap: 60}}>
                    <div>
                        <ul>
                            <li>Stripe Events
                                <ul>
                                    <li><Text code>accountHistory</Text></li>
                                    <li><Text code>prices</Text></li>
                                    <li><Text code>customers</Text></li>
                                    <li><Text code>customerCountry</Text></li>
                                </ul>
                            </li>
                            <li>
                                Stripe Sigma
                                <ul>
                                    <li><Text code>usageRecords</Text></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ArrowRightOutlined style={{ fontSize: 30}} />
                    </div>
                    <div>
                        <Text code>mrrByProductGroup</Text>
                    </div>

                </div>
            </Collapse.Panel>
        </Collapse>

        <Paragraph>
            <Title level={5}>
                <BookTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Further Reading
            </Title>
        </Paragraph>

        <Paragraph>
            <ul>
                <li><Link to={'/revenue/mrr?help=yes'}>MRR Documentation</Link></li>
            </ul>
        </Paragraph>


        </div>

}

export default DocsMrrGrowthRate