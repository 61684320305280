import React, {useContext, useState} from 'react';
import Page from "../layouts/Page";
import {gql, useMutation, useQuery} from "@apollo/client";
import PageLoading from "../components/PageLoading";
import {Button, Cascader, Input, Modal, Select, Table} from "antd";
import {Dayjs} from "../utils/dayjs";
import getDateRangeFilterDefaults from "../utils/getDateRangeFilterDefaults";
import reports from "../utils/reports";
import regionList from "../utils/regionList";
import countryList from "../utils/countryList";
import { DefaultOptionType } from 'antd/es/cascader';
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {AuthContext} from "../components/AuthContext";


const GET_DATA = gql`
query data {

getUsers {
   id
   email
   name
   picture
   created
   permissions {
        id
        type
        permission
   }
}

  }
`

const getAccessText = (record, type) => {
    let permissionText
    const permissions = record.permissions.filter(item => item.type === type).map(permission => permission.permission)
    if(permissions.includes('all')) {
        permissionText = 'All'
    } else if(permissions.length > 0 ) {
        permissionText = 'Individual'
    } else {
        permissionText = 'None'
    }

    return permissionText
}


const UserManagement: React.FC = () => {
    const {user} = useContext(AuthContext)

    const pageTitle = 'User Management'
    const [searchState, setSearchState] = useState<string>('')
    const [expandedKeys, setExpandedKeys] = useState<any[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [formState, setFormState] = useState<any[]>([]);
    const [modalLoading, setModalLoading] = useState<boolean>(false);

    const [updatePermissions] = useMutation(gql`
    mutation updatePermissions($user: Int!, $permissions: [PermissionInput]) {
      updatePermissions(user: $user, permissions: $permissions) {
        permission
        type
      }
    }
  `)

    /* Retrieve Data */
    const { data, loading, refetch } = useQuery(GET_DATA)

    /* Handle Loading State */
    if(loading) {
        return <PageLoading title={pageTitle} />
    }


    const handleShowPermissionDetails = (key) => {
        if(expandedKeys.includes(key)) {
            setExpandedKeys([])
        } else {
            setExpandedKeys([key])
        }
    }

    const columns = [
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (item, record) => {
                return <div>
                    <div style={{display:'flex', alignItems: 'center', height:100}}>
                        <img src={record.picture} style={{ width:36, height:36, borderRadius: 48}}/>
                        <div style={{marginLeft: 8}}>
                            <div style={{ fontSize: 12, color:'#999', marginBottom: -3}}>{record.name}</div>
                            <div>{record.email}</div>
                        </div>
                    </div>
                </div>
            }
        },
        {
            title: 'Category Access',
            dataIndex: 'category-access',
            key: 'category-access',
            render: (item, record) => {
                return <div>{getAccessText(record, 'category')}</div>
            }
        },
        {
            title: 'Page Access',
            dataIndex: 'page-access',
            key: 'page-access',
            render: (item, record) => {
                return <div>{getAccessText(record, 'page')}</div>
            }
        },
        {
            title: 'Region Access',
            dataIndex: 'region-access',
            key: 'region-access',
            render: (item, record) => {
                return <div>{getAccessText(record, 'region')}</div>
            }
        },
        {
            title: 'Country Access',
            dataIndex: 'country-access',
            key: 'country-access',
            render: (item, record) => {
                return <div>{getAccessText(record, 'country')}</div>
            }
        },
        {
            title: 'Admin',
            dataIndex: 'admin',
            key: 'admin',
            render: (item, record) => {
                return <div>{record.permissions.filter(permission => permission.type === 'admin' && permission.permission === 'yes').length > 0 ? 'Yes' : 'No'}</div>
            }
        },
        {
            title: 'Tools',
            dataIndex: 'tools',
            key: 'tools',
            render: (item, record) => {


                return <div>
                    <Button
                        onClick={() => handleShowPermissionDetails(record.id)}
                        type={"primary"}
                    >Permission Details</Button>
                    {
                        user.email !== record.email
                        ? <Button
                                style={{ marginLeft: 10}}
                                type={"primary"}
                                onClick={() => showModal(record)}
                            >Edit Permissions</Button>
                            : null
                    }
                </div>
            }
        },

    ];


    const showPermissions = (record) => {
        const categoryPermissions = record.permissions.filter(permission => permission.type === 'category').map(permission => permission.permission)
        if(categoryPermissions.includes('all')) {
            categoryPermissions.push(...reports.map(report => report.id))
        }

        const pagePermissions = record.permissions.filter(permission => permission.type === 'page').map(permission => permission.permission)
        const pagePermissionList = reports.map(report => ({
            ...report,
            items: report.items.filter(item => pagePermissions.includes(item.id) || categoryPermissions.includes(report.id))
        })).filter(report => report.items.length > 0)


        const regionPermissions = record.permissions.filter(permission => permission.type === 'region').map(permission => permission.permission)
        if(regionPermissions.includes('all')) {
            regionPermissions.push(...Object.keys(regionList))
        }

        const countryPermissions = record.permissions.filter(permission => permission.type === 'country').map(permission => permission.permission)
        if(countryPermissions.includes('all')) {
            countryPermissions.push(...Object.keys(countryList))
        } else {
            regionPermissions.filter(region => region !== 'all').forEach(region => {
                countryPermissions.push(...regionList[region].countries)
            })
        }


        return <div style={{ display:'flex', gap:60}}>
            <div>
                <div style={{fontWeight: 700}}>Categories</div>
                <ul style={{marginLeft: 0, paddingLeft: 16}}>
                    {categoryPermissions.filter(category => category !== 'all').map(category => <li key={`categoryPermissions-${category}`}>{reports.filter(report => report.id === category)[0].menuTitle}</li>)}
                </ul>
            </div>
            <div>
                <div style={{fontWeight: 700}}>Pages</div>
                <ul style={{marginLeft: 0, paddingLeft: 16}}>
                    {pagePermissionList.map(category => <li key={`pagePermissionCategory-${category.id}`}>
                        {category.menuTitle}
                        <ul>
                            {category.items.map(item => <li key={`pagePermissionPage-${item.id}`}>{item.menuTitle}</li>)}
                        </ul>
                    </li>)}
                </ul>
            </div>
            <div>
                <div style={{fontWeight: 700}}>Regions</div>
                <ul style={{marginLeft: 0, paddingLeft: 16}}>
                    {regionPermissions.filter(region => region !== 'all').map(region => <li key={`regionPermission-${region}`}>{regionList[region].name}</li>)}
                </ul>
            </div>

            <div>
                <div style={{fontWeight: 700}}>Countries</div>
                <ul style={{marginLeft: 0, paddingLeft: 16, columnCount: 7, columnGap: 32, fontSize:10}}>
                    {countryPermissions.filter(country => country !== 'all').map(country => <li key={`countryPermission-${country}`}>{countryList[country]}</li>)}
                </ul>
            </div>

        </div>
    }

    const getFormStateFromPermissions = (permissions) => {
        return permissions.map(permission => ([
            permission.type,
            permission.permission
        ]))
    }

    const showModal = (user) => {
        setIsModalOpen(true);
        setEditingUser(user.id)
        setFormState(getFormStateFromPermissions(user.permissions))
    }

    const handleOk = async () => {
        setModalLoading(true)
        const permissions = formState.map(item => ({
            type: item[0],
            permission: item[item.length - 1]
        }))

        const { data } = await updatePermissions({
            variables: {
                user: editingUser,
                permissions: permissions
            },
        })

        refetch()
        setModalLoading(false)
        setIsModalOpen(false)
        setEditingUser(null)
        setFormState([])
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setEditingUser(null)
        setFormState([])
    }

    interface Option {
        value: string | number;
        label: string;
        children?: Option[];
    }

    const options: Option[] = [
        {
            value: 'category',
            label: 'Category',
            children: [
                {
                    value: 'all',
                    label: 'All',
                },
                ...reports.map((report: any) => ({
                    value: report.id,
                    label: report.menuTitle
                }))
            ],
        },
        {
            value: 'page',
            label: 'Page',
            children: reports.map((report: any) => ({
                value: report.id,
                label: report.menuTitle,
                children: [
                    {
                        value: 'all',
                        label: 'All',
                    },
                    ...report.items.map(item => ({
                        value: item.id,
                        label: item.menuTitle,
                    }))
                ]
            })),
        },
        {
            value: 'region',
            label: 'Region',
            children: [
                {
                    value: 'all',
                    label: 'All',
                },
                ...Object.keys(regionList).map(region => ({
                    value: region,
                    label: regionList[region].name
                }))
            ],
        },
        {
            value: 'country',
            label: 'Country',
            children: [
                {
                    value: 'all',
                    label: 'All',
                },
                ...Object.keys(countryList).map(country => ({
                    value: country,
                    label: countryList[country]
                }))
            ],
        },
    ];

    const filter = (inputValue: string, path: DefaultOptionType[]) =>
        path.some(
            (option) => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
        );

    const cascaderChangeHandler = (item, i) => {
        const newFormState : any[] = [...formState]
        newFormState[i] = item
        setFormState(newFormState)
    }

    const deletePermission = (i) => {
        setFormState(formState.filter((item, n) => i !== n))
    }

    const addPermission = () => {
        const newFormState : any[] = [...formState, []]
        setFormState(newFormState)
    }

    const editUserContent = () => {
        return <div>
            {formState.map( (permission: any, i) => {
                return <div style={{marginTop: 10}} key={`cascader-${i}`}>
                    <Cascader
                        style={{width: 300}}
                        showSearch={{ filter }}
                        onChange={(item) => cascaderChangeHandler(item, i)}
                        value={permission}
                        options={options}
                        placeholder="Please select"
                    />
                    <Button
                        style={{ marginLeft: 10}}
                        onClick={() => deletePermission(i) }
                        type="primary"
                        danger
                        shape="circle"
                        icon={<DeleteOutlined />} />

                </div>

            })}

            <Button
                onClick={() => addPermission()}
                style={{marginTop:20}}
                type={'primary'}
                icon={<PlusCircleOutlined />}
            >Add Permission</Button>
        </div>
    }

    const dataSource = data.getUsers.filter(item => item.name.toLowerCase().includes(searchState.toLowerCase()) || item.email.toLowerCase().includes(searchState.toLowerCase()))

    return (
        <Page frame={true} title={pageTitle}>
            <Input style={{ marginBottom:16}} placeholder={"Search"} value={searchState}  onChange={(e) => setSearchState(e.target.value)} />
            <Table
                expandable={{
                    expandedRowKeys: expandedKeys,
                    expandedRowRender: (record) => showPermissions(record),
                    rowExpandable: (record) => record,
                    showExpandColumn: false
                }}
                dataSource={dataSource}
                columns={columns}
                rowKey={(record) => record.id}
            />
            <Modal confirmLoading={modalLoading} title="Set User Permissions" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                {editingUser ? editUserContent() : null}
            </Modal>

        </Page>

    )}

export default UserManagement;