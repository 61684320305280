export default {
    "spanish": {
        name: "Spanish",
        countries: [
            'AR',
            'VE',
            'BO',
            'CL',
            'CO',
            'CR',
            'CU',
            'DO',
            'EC',
            'GQ',
            'GT',
            'HN',
            'MX',
            'NI',
            'PA',
            'PY',
            'PR',
            'ES',
            'UY',
            'PE',
            'SV'
        ]
    },
    "german": {
        name: 'German',
        countries: [
            "DE",
            "AT",
            "CH"
        ]
    },
    "french": {
        name: 'French',
        countries: [
            "FR",
            "LU",
            "BE"
        ]
    },
    "portuguese": {
        name: 'Portoguese',
        countries: [
            "PT",
            "BR",
        ]
    },
    "italian": {
        name: 'Italian',
        countries: [
            "IT"
        ]
    },
    "japanese": {
        name: 'Japanese',
        countries: [
            "JP"
        ]
    },
    "dutch": {
        name: 'Dutch',
        countries: [
            "NL"
        ]
    },

    "swedish": {
        name: 'Swedish',
        countries: [
            "SE"
        ]
    },

    "danish": {
        name: 'Danish',
        countries: [
            "DK"
        ]
    },


}
