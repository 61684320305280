import React from "react";
import {Collapse, Table, Typography} from "antd";
import {ArrowRightOutlined, BookTwoTone, CalculatorTwoTone, DatabaseTwoTone, InfoCircleTwoTone} from "@ant-design/icons";
import { BlockMath } from 'react-katex';
import {Link} from "react-router-dom";

const DocsNetRevenue: React.FC = (props) => {
    const { Text, Paragraph, Title } = Typography;


    return <div>


        <Paragraph>
            <Title level={5} style={{marginTop:0}}>
                <CalculatorTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Formula
            </Title>
        </Paragraph>

        <BlockMath math="\large
        \frac{\sum_{}\text{MRR Less New Revenue previous 365 days}}{\text{Current MRR}}
        "/>
        <Paragraph>
            <Title level={5}>
                <DatabaseTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Data Source
            </Title>
        </Paragraph>

        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="mrrByProductGroup" key="dependency">
                <div style={{display: 'flex', alignItems:'center', gap: 60}}>
                    <div>
                        <ul>
                            <li>Stripe Events
                                <ul>
                                    <li><Text code>accountHistory</Text></li>
                                    <li><Text code>prices</Text></li>
                                    <li><Text code>customers</Text></li>
                                    <li><Text code>customerCountry</Text></li>
                                </ul>
                            </li>
                            <li>
                                Stripe Sigma
                                <ul>
                                    <li><Text code>usageRecords</Text></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ArrowRightOutlined style={{ fontSize: 30}} />
                    </div>
                    <div>
                        <li><Text code>chartMrr</Text></li>
                        <li><Text code>mrrBreakout</Text></li>
                    </div>
                    <div>
                        <ArrowRightOutlined style={{ fontSize: 30}} />
                    </div>
                    <div>
                        <Text code>chartNrr</Text>
                    </div>

                </div>
            </Collapse.Panel>
        </Collapse>



        </div>

}

export default DocsNetRevenue