import React from "react";
import {Collapse, Typography} from "antd";
import DocsAccountHistory from "./DocsAccountHistory";
import DocsPrices from "./DocsPrices";
import {
    ArrowRightOutlined, BookTwoTone,
    CalculatorTwoTone,
    DatabaseTwoTone,
    ExclamationCircleTwoTone, InfoCircleTwoTone
} from "@ant-design/icons";
import { BlockMath } from 'react-katex';

const DocsActiveSubscriptions: React.FC = (props) => {
    const { Text, Paragraph, Title } = Typography;

    return <div>


        <Paragraph>
            <Title level={5} style={{marginTop:0}}>
                <CalculatorTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Formula
            </Title>
        </Paragraph>

        <BlockMath math="\large
        \sum_{} \text{Active Subscriptions} "/>


        <Paragraph>
            <Title level={5}>
                <DatabaseTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Data Source
            </Title>
        </Paragraph>

        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="activeSubscriptions" key="dependency">
                <div style={{display: 'flex', alignItems:'center', gap: 60}}>
                    <div>
                        <ul>
                            <li>Stripe Events
                                <ul>
                                    <li><Text code>accountHistory</Text></li>
                                    <li><Text code>prices</Text></li>
                                    <li><Text code>customers</Text></li>
                                    <li><Text code>customerCountry</Text></li>
                                </ul>
                            </li>
                            <li>
                                Stripe Sigma
                                <ul>
                                    <li><Text code>usageRecords</Text></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ArrowRightOutlined style={{ fontSize: 30}} />
                    </div>
                    <div>
                        <Text code>activeSubscriptions</Text>
                    </div>



                </div>
            </Collapse.Panel>
        </Collapse>

        <Paragraph>
            <Title level={5}>
                <InfoCircleTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Details
            </Title>
        </Paragraph>

        <Paragraph>
            Active Subscriptions count the number of unique subscriptions within the Account History table on a
            given day where the quantity used is greater than 0 and the product group is <Text code>app</Text>,
            <Text code>db</Text> or <Text code>wp</Text>.
        </Paragraph>


        <Paragraph style={{marginBottom:0}}><ExclamationCircleTwoTone twoToneColor={'#ed6605'} style={{ marginRight: 6}} /> <strong>Pay as You Go Services</strong></Paragraph>

        <Paragraph>
            Note that subscriptions for pay as you go services will show greater movement when it comes to
            activity. Using our services for 10 minutes each month is a perfectly acceptable use case, due to which we
            can not close down a subscription when a service in it is not being used.
        </Paragraph>
        <Paragraph>
            To get around this issue a pay as you go subscription is only marked as active when it produced usage on the
            given day. If the customer switches there app on every third day for 2 hours then the subscription will
            be marked as active on every third day.
        </Paragraph>
        <Paragraph>
            The two alternatives would be to keep the subscription active indefinitely which I think is not
            a better reflection of reality or to have certain activation thresholds. I also don't deem that to be a
            good reflection of reality and it would take a disproportionate amount of development time. I think
            the numbers will even out, especially at scale.
        </Paragraph>

        <Paragraph style={{marginBottom:0}}><ExclamationCircleTwoTone twoToneColor={'#ed6605'} style={{ marginRight: 6}} /> <strong>Customers and Subscriptions</strong></Paragraph>
        <Paragraph>
            Note that customers may have multiple subscriptions which is why you will see a higher number of subscriptions
            than customers
        </Paragraph>



        <Paragraph>
            <Title level={5}>
                <BookTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Further Reading
            </Title>
        </Paragraph>

        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="Understanding Account History" key="account-history">
                <DocsAccountHistory />
            </Collapse.Panel>

            <Collapse.Panel header="Understanding Prices" key="prices">
                <DocsPrices />
            </Collapse.Panel>

        </Collapse>



    </div>

}



export default DocsActiveSubscriptions