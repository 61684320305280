import dayjs, { Dayjs } from "./dayjs";

export default function(searchParams, defaultStart, defaultEnd) : [Dayjs, Dayjs] {
    const startParam = searchParams.get('start') || ''
    const endParam = searchParams.get('end') || ''
    const start = startParam.match(/^\d\d\d\d-\d\d-\d\d$/) ? dayjs(startParam) : null
    const end = endParam.match(/^\d\d\d\d-\d\d-\d\d$/) ? dayjs(endParam) : null
    if(start && end && end.isAfter(start)) {
        return [start, end]
    }

    return [
        defaultStart,
        defaultEnd
    ]

}