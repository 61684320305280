type DateBounds = {
    start: string
    end: string
}

export enum ChartDateGroupBy {
    day = 'day',
    week = 'week',
    month = 'month',
    year = 'year'
}
export enum ChartDataType {
    state = 'state',
    change = 'change'
}




export enum ChartSummaryAggregation {
    sum = 'sum',
    average = 'average',
    state = 'state',
}

export enum ChartSummaryComparisonType {
    inPeriod = 'inPeriod',
    previousPeriod = 'previousPeriod'
}


type ChartData = {
    bounds: {
        visibleDates: DateBounds
        visibleMinimum: number
        visibleMaximum: number
        allDates: DateBounds
        previousPeriod: DateBounds
    }
    dateGroupBy: ChartDateGroupBy
    summary: {
        aggregation: ChartSummaryAggregation
        comparisonType: ChartSummaryComparisonType
        currentValue: number
        previousValue: number
    }
    stackData: {
        stackField: string
        stacks: {
            value: string
            label: string
        }[]
    }
    dataPoints: {
        x: string
        visible: Boolean
        dateRange: DateBounds
        y: number
        stacks: {
            stack: string
            y: number
        }[]
    }[]
}

export default ChartData
