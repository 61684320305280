import React, { useState, useContext } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import {useMutation, gql} from '@apollo/client'
import {AuthContext} from "../components/AuthContext";
import {useNavigate} from 'react-router-dom'
import { Button } from 'antd'
import { GoogleOutlined } from '@ant-design/icons';



const Login: React.FC = () => {
    let navigate = useNavigate();

    const [ profile, setProfile ] = useState({});

    const { dispatch, user } = useContext(AuthContext)
    const auth = useContext(AuthContext)

    const [login] = useMutation(gql`
    mutation Login($access_token: String!) {
      login(access_token: $access_token) {
        name
        email
        picture
        created
        token
        permissions {
            type
            permission
        }
      }
    }
  `)

    const logOut = () => {
        googleLogout();
        setProfile({});
    };
    const googleLogin = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            const { data } = await login({
                variables: {
                    access_token: codeResponse.access_token,
                },
            })

        

            const token = data?.login?.token
            if (token) {
                dispatch({ type: 'login', user: data.login })
                navigate('/')
                navigate(0)

            }

        },
        onError: (error) => console.log('Login Failed:', error)
    });

    return (
        <div style={{ height:'100vh', display:'flex', justifyContent:'center', paddingTop: '30vh'}}>
            <div>
                <img src='logo192.png' style={{ display:'block', margin:'0 auto 12px auto', width:80, height:80}}/>
                <div style={{ fontSize: 24, fontWeight:600}}>Kinsta Business Intelligence</div>
                <br />
                <br />
                {Object.keys(profile).length > 0 ? (
                    <div>
                       You are logged in
                        <button onClick={logOut}>Log out</button>
                    </div>
                ) : (
                <div onClick={() => googleLogin()} style={{display:'flex', alignContent: 'center', justifyContent:'center'}}>
                    <Button icon={<GoogleOutlined />} type='primary'>Log in with Google</Button>
                </div>
                )}

            </div>
        </div>
    )
};



export default Login;