import React from "react";
import {SmileOutlined} from "@ant-design/icons";

const Loading: React.FC = (props) => {
    return <div style={{ textAlign: 'center', padding:'40px 0' }}>
        <SmileOutlined style={{ fontSize: 80, color: '#eee' }} />
        <div style={{ marginTop:20, fontSize: 20, color: '#ddd', textTransform:'uppercase' }}>Hang On, Loading data</div>
    </div>

}

export default Loading