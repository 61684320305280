import React, {createContext, useEffect, useReducer} from 'react'
import {get} from 'lodash'
import axios from 'axios'

const LOCAL_STORAGE_KEY = 'kbiToken'
type AuthState = {
    name: string
    email: string
    picture: string
    created: string
    token: string
    permissions: {
        type: string,
        permission: string
    }[]
}

type AuthAction =
    | {
        type: 'login'
        user: {
            name: string
            email: string
            picture: string
            created: string
            token: string
            permissions: {
                type: string,
                permission: string
            }[]
        }
    }
    | {
    type: 'logout'
    }

export type AuthContextType = {
    user: AuthState
    dispatch: React.Dispatch<AuthAction>
}

let initialState
try {
    initialState = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY) || '')
} catch(e) {
    initialState = null
}

const AuthContext = createContext<AuthContextType>({
    user: initialState,
    dispatch: () => {},
})


const AuthProvider: React.FC<{ children: React.ReactElement }> = ({ children }) => {

    useEffect(() => {
        async function isLoggedIn() {
            const state = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY) || '{}')

            const result = await axios({
                method: 'post',
                url: process.env?.REACT_APP_ENV === 'development' ? 'http://localhost:4000/graphql' : process.env.REACT_APP_GRAPHQL_ENDPOINT || '/graphql',
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'Apollo-Require-Preflight' : 'true'
                },
                data: {
                    "query": `query Query($token: String!) {
                          isValidToken(token: $token)
                        }`,
                    "variables": { token: get(state, 'token', '') }
                }
            })

            if(!get(result, 'data.data.isValidToken', false)) {
                window.localStorage.removeItem(LOCAL_STORAGE_KEY)
            }

        }

        isLoggedIn()
    })


    //@ts-ignore
    const [user, dispatch] = useReducer(
        (user: AuthState, action: AuthAction) => {
            switch (action.type) {
                case 'login':
                    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(action.user))
                    return action.user
                case 'logout':
                    window.localStorage.removeItem(LOCAL_STORAGE_KEY)
                    return null
                default:
                    throw new Error(`Invalid action ${action}`)
            }
        },
        initialState
    )



    return (
        <AuthContext.Provider value={{ user, dispatch }}>
            {children}
        </AuthContext.Provider>
)
}

export { AuthContext, AuthProvider }