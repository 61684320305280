import React from "react";
import {Button, Select, Space} from "antd";
import {AreaChartOutlined, BarChartOutlined} from "@ant-design/icons";

const typeSelectorChangeHandler = (type, filter, setTypeFilter, searchParams, setSearchParams) => {
    const newType = type || filter
    setTypeFilter(newType)
    setSearchParams({
        ...Object.fromEntries([...searchParams]),
        type: newType
    })

}

const handleStackChange = (stackByTypeState, setStackByType, searchParams, setSearchParams, setFilter) => {
    const newState = stackByTypeState === 'yes' ? 'no' : 'yes'
    setStackByType(newState)
    setFilter([])
    setSearchParams({
        ...Object.fromEntries([...searchParams]),
        stackByType: newState
    })

}

const renderStackButton = (stackByTypeState, setStackByType, searchParams, setSearchParams, setFilter) => {
    const type = stackByTypeState === 'yes' ? 'primary' : 'primary'
    const icon = stackByTypeState === 'yes' ? <AreaChartOutlined /> : <BarChartOutlined />
    const text = stackByTypeState === 'yes' ? 'Unstack' : 'Stack'
    return <Button
        onClick={() => handleStackChange(stackByTypeState, setStackByType, searchParams, setSearchParams, setFilter)}
        type={type}
    >{icon} {text}</Button>
}

const TypeSelector: React.FC<{ types, filter, setFilter, searchParams, setSearchParams, allowStack, stackByTypeState, setStackByType}> = ({ types, filter, setFilter, searchParams, setSearchParams, allowStack, stackByTypeState, setStackByType}) => {
    return <div>
        <Space.Compact>
            <Select
                mode={"multiple"}
                disabled={stackByTypeState === 'yes'}
                placeholder={"Please select"}
                allowClear={true}
            value={filter}
            style={{width: 160}}
            onChange={(type) => typeSelectorChangeHandler(type, filter, setFilter, searchParams, setSearchParams)}
            options={types}
            />
            {allowStack ? renderStackButton(stackByTypeState, setStackByType, searchParams, setSearchParams, setFilter) : null}
        </Space.Compact>
    </div>
}

export default TypeSelector
