import countryList from "./countryList";
import regionList from "./regionList";
import {uniq} from "lodash";

export default function(permissions) {
    const allowedCountries : any[] = []
    const countryPermissions = permissions.filter(permission => permission.type === 'country').map(permission => permission.permission)
    const regionPermissions = permissions.filter(permission => permission.type === 'region').map(permission => permission.permission)

    if(countryPermissions.includes('all')) {
        allowedCountries.push(...Object.keys(countryList))
    } else {
        countryPermissions.forEach(country => {
            allowedCountries.push(country)
        })
    }

    if(regionPermissions.includes('all')) {
        allowedCountries.push(...Object.keys(regionList).map(region => regionList[region].countries).flat())

    } else {
        regionPermissions.forEach(region => {
            allowedCountries.push(...regionList[region].countries)
        })
    }

    return uniq(allowedCountries).map(countryIso => ({
        value: `country-${countryIso}`,
        title: countryList[countryIso]
    }))

}