import React, {useContext} from "react";
import {Navigate} from "react-router-dom";
import {AuthContext} from "./AuthContext";
import {has} from 'lodash'

const ProtectedRoute : React.FC<{ children: React.ReactElement }> = ({ children }) => {
    const {user} = useContext(AuthContext)

    if (!has(user, 'token')) {
        return <Navigate to="/login" replace />;
    }

    return children;
};

export default  ProtectedRoute