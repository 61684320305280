import React from "react";
import {Collapse, Table, Typography} from "antd";
import {ExclamationCircleTwoTone} from "@ant-design/icons";
import DocsPrices from "./DocsPrices";

const DocsAccountHistory: React.FC = (props) => {
    const { Text, Paragraph, Title } = Typography;

    const tableData = [{
            id: 1,
            date: '2023-04-20',
            customer: 1,
            quantity: 1,
            price: 'Business 1',
            productType: 'wp',
            type: 'New',
            day: 'current'
        },


        {
            id: 2,
            date: '2023-04-19',
            customer: 2,
            quantity: 1,
            price: 'Business 1',
            productType: 'wp',
            type: 'Churn',
            day: 'previous'
        },
        {
            id: 3,
            date: '2023-04-19',
            customer: 2,
            quantity: 3,
            price: 'Hourly Backup Addon',
            productType: 'hourly-backup',
            type: 'Churn',
            day: 'previous'
        },


        {
            id: 4,
            date: '2023-04-19',
            customer: 3,
            quantity: 1,
            price: 'Business 1',
            productType: 'wp',
            type: 'None',
            day: 'previous',
            example: 2
        },
        {
            id: 5,
            date: '2023-04-20',
            customer: 3,
            quantity: 1,
            price: 'Business 1',
            productType: 'wp',
            type: 'None',
            day: 'current',
            example: 2
        },
        {
            id: 6,
            date: '2023-04-19',
            customer: 3,
            quantity: 1,
            price: 'Redis Addon',
            productType: 'redis',
            type: '',
            day: 'previous',
            example: 2
        },
        {
            id: 7,
            date: '2023-04-20',
            customer: 3,
            quantity: 2,
            price: 'Redis Addon',
            productType: 'redis',
            type: 'Upgrade',
            day: 'current',
            example: 2
        },
        {
            id: 8,
            date: '2023-04-20',
            customer: 3,
            quantity: 1,
            price: 'Hourly Backup Addon',
            productType: 'hourly-backup',
            type: 'expansion',
            day: 'current',
            example: 2
        },


        {
            id: 9,
            date: '2023-04-19',
            customer: 4,
            quantity: "28,800",
            price: 'App S1',
            productType: 'app-s1',
            type: 'usageIncrease',
            day: 'previous',
            example: 3
        },
        {
            id: 10,
            date: '2023-04-20',
            customer: 4,
            quantity: "86,400",
            price: 'App S1',
            productType: 'app-s1',
            type: 'usageIncrease',
            day: 'current',
            example: 3
        }
    ]

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: 'Product Type',
            dataIndex: 'productType',
            key: 'productType',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },

    ];

    return <div>


        <Paragraph>
            Account Activity relies on the data in Account History and determines the <strong>type</strong> of MRR movement
            it represents. It does this by comparing all Account History items on one day (which I will refer to as the
            <strong>current day</strong>) to all items on the <strong>previous day</strong>.
        </Paragraph>


        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="New & Reactivation" key="new">
                <Paragraph>
                    If a customer has Account History items on the current day but has no items on the previous day and has never
                    had any items previously, we consider all items for the current day to be new MRR.
                </Paragraph>

                <Paragraph>
                    <Table size={'small'} rowKey={(record) => record.id} pagination={false}
                           dataSource={tableData.filter(item => item.type === 'New')}
                           columns={columns} />
                </Paragraph>


                <Paragraph>
                    In the table above I've listed the account activity for customer <Text code>1</Text> for the current day
                    (2023-04-20) and the previous day. As there are no entries for the previous day, all items are considered
                    as new MRR.
                </Paragraph>

                <Paragraph>
                    Reactivation is almost exactly the same as New. The only difference is that while the customer did not
                    have entries for the previous day, they did have prior entries somewhere in the Account History. This
                    indicates that they have been a paying customer some time in the past.
                </Paragraph>

            </Collapse.Panel>


            <Collapse.Panel header="Churn" key="churn">
                <Paragraph>
                    Churn is the opposite of new. If a customer has Account History items on the previous day but not items
                    on the current day we consider all items for the previous day to contribute their MRR to churn.
                </Paragraph>

                <Paragraph>
                    <Table size={'small'} rowKey={(record) => record.id} pagination={false}
                           dataSource={tableData.filter(item => item.type === 'Churn')}
                           columns={columns} />
                </Paragraph>



            </Collapse.Panel>

            <Collapse.Panel header="Upgrade, Downgrade, Expansion, Contraction" key="upgrade">
                <Paragraph>
                    When a customer has entries for the current date and the previous date we first take away any items that
                    are common across the two then segment by product type. For each individual product type we compare the
                    two lists
                </Paragraph>

                <Paragraph>
                    If there are entries for the current date for a given product type and there are none for the previous date
                    we consider that <strong>Expansion</strong>. This means that a customer was already with us, had paying plans
                    but they branched out into a new product. For example, this could be a customer purchasing a new addon,
                    or a WordPress customer trying out Application Hosting.
                </Paragraph>
                <Paragraph>
                    If the opposite is true - there are entries for the previous date but not the current date - we consider
                    that <strong>Contraction</strong>.
                </Paragraph>

                <Paragraph>
                    Once we've handled expansion and contraction we remove usage based items for the time being, they will be
                    handled in the next section.
                </Paragraph>

                <Paragraph>
                    If there are entries for both dates we calculate the MRR for both dates. If the MRR is larger for the
                    current date we consider all participating items contributing to the <strong>Upgrade</strong> type. If
                    the MRR is lower for the current date we consider them to be a <strong>Downgrade</strong>.
                </Paragraph>

                <Paragraph>
                    The example below shows a more complex example involving multiple types
                </Paragraph>


                <Paragraph>
                    <strong>Previous Day:</strong>
                    <Table size={'small'} rowKey={(record) => record.id} pagination={false}
                           dataSource={tableData.filter(item => item.example === 2 && item.day === 'previous')}
                           columns={columns.filter(item => item.key !== 'type')} />
                </Paragraph>
                <Paragraph>
                    <strong>Current Day:</strong>
                    <Table size={'small'} rowKey={(record) => record.id} pagination={false}
                           dataSource={tableData.filter(item => item.example === 2 && item.day === 'current')}
                           columns={columns.filter(item => item.key !== 'type')} />
                </Paragraph>

                <Paragraph>
                    Note here that the first item in the two tables is unchanged, this can be disregarded from an MRR
                    breakout point of view. We're left with two distinct product types which we need to investigate
                    individually.
                </Paragraph>

                <Paragraph>
                    On the previous day the customer used 1 Redis addon, on the current day they are using 2. This means
                    that the MRR for the redis product type for this customer is larger on the current day, hence this is
                    an upgrade. To arrive at the difference in MRR we will subtract the quantities from each other, so
                    essentially this is "one redis adddon's worth" of MRR upgrade.
                </Paragraph>

                <Paragraph>
                    The customer was not using an hourly backup addon on the previous day, so this constitutes expansion
                </Paragraph>


            </Collapse.Panel>
            <Collapse.Panel header="Usage Increase, Usage Decrease" key="usage">
                <Paragraph>
                    When dealing with upgrades, downgrades, contraction and expansion we removed usage based items once
                    we determined contraction and expansion. Usage increase and usage decrease is essentially what
                    upgrade and downgrade is to most PaaS services.
                </Paragraph>
                <Paragraph>
                    This is due to a number of factors. For example, your application may use 3 App S1 pods during regular
                    operations. However, three times a week an App S4 may be created, run a calculation and then be removed
                    automatically. This causes usage to change regularly but it doesn't really indicate upgrade/downgrade
                    intent.
                </Paragraph>

                <Paragraph>
                    <Table size={'small'} rowKey={(record) => record.id} pagination={false}
                           dataSource={tableData.filter(item => item.example === 3 )}
                           columns={columns.filter(item => item.key !== 'type')} />
                </Paragraph>

                <Paragraph>
                    The table above shows that a customer's usage went from 28,800 seconds (8 hours) to 86,400 seconds
                    (24 hours) from the previous to the current day. This constitutes a <strong>Usage Increase</strong>
                    type. If the opposite were true, we would consider it a <strong>Usage Decrease</strong>.
                </Paragraph>

            </Collapse.Panel>


        </Collapse>


    </div>

}

export default DocsAccountHistory