import React from "react";
import {Collapse, Typography} from "antd";
import {Link} from "react-router-dom";
import {
    ArrowRightOutlined,
    BookTwoTone,
    CalculatorTwoTone,
    DatabaseTwoTone,
    InfoCircleTwoTone
} from "@ant-design/icons";
import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';

const DocsAddons: React.FC = (props) => {
    const { Text, Paragraph, Title } = Typography;


    return <div>

        <Paragraph>
            <Title level={5} style={{marginTop:0}}>
                <CalculatorTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Formula
            </Title>
        </Paragraph>

        <BlockMath math="\footnotesize \text{For all active subscription items on a given day}"/>
        <BlockMath math="\large
        \sum_{} quantity \times price \times  \begin{cases}
            \enspace \large 1 \footnotesize \text{ if interval is  month } \\
            \enspace \large 1/12 \footnotesize \text{ if interval is year } \\
            \enspace \large 30.436875 \footnotesize \text{ if interval is day } \\
        \end{cases} \times \begin{cases}
            \enspace \large 1 \footnotesize \text{ if report is MRR } \\
            \enspace \large 12 \footnotesize \text{ if report is ARR } \\
        \end{cases}


        "/>
        <Paragraph>
            <Title level={5}>
                <DatabaseTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Data Source
            </Title>
        </Paragraph>

        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="mrrByProductGroup" key="dependency">
                <div style={{display: 'flex', alignItems:'center', gap: 60}}>
                    <div>
                        <ul>
                            <li>Stripe Events
                                <ul>
                                    <li><Text code>accountHistory</Text></li>
                                    <li><Text code>prices</Text></li>
                                    <li><Text code>customers</Text></li>
                                    <li><Text code>customerCountry</Text></li>
                                </ul>
                            </li>
                            <li>
                                Stripe Sigma
                                <ul>
                                    <li><Text code>usageRecords</Text></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ArrowRightOutlined style={{ fontSize: 30}} />
                    </div>
                    <div>
                        <Text code>mrrByProductGroup</Text>
                    </div>

                </div>
            </Collapse.Panel>
        </Collapse>


        <Paragraph>
            <Title level={5}>
                <InfoCircleTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Details
            </Title>
        </Paragraph>

        <Paragraph>
            This report is the same as our MRR report but it is scoped to only show the sum of addons or selected
            addons.
        </Paragraph>

        <Paragraph>
            <Title level={5}>
                <BookTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Further Reading
            </Title>
        </Paragraph>
        <Paragraph>
            <ul>
                <li><Link to={'/revenue/mrr'}>MRR</Link></li>
            </ul>
        </Paragraph>



    </div>

}

export default DocsAddons