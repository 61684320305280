import React, {useContext, useState} from 'react';
import {Button} from 'antd';
import { Layout } from 'antd';
import {useNavigate, Outlet, useLocation, useSearchParams} from "react-router-dom";
import {AuthContext} from "../components/AuthContext";
import { googleLogout } from '@react-oauth/google';
import reports from '../utils/reports'
import styled from 'styled-components';
import {gql, useQuery} from "@apollo/client";
import PageLoading from "../components/PageLoading";

const { Content, Sider } = Layout;

const GET_DATA = gql`
query data {
   
   
   getCurrentUserFrontendPermissions{
        permission
        type
   }

   
}`;

interface MyProps {
}

const Main: React.FC<MyProps> = (props) => {

    let navigate = useNavigate();
    let location = useLocation();
    const {user} = useContext(AuthContext)
    let [searchParams, setSearchParams] = useSearchParams();

    const { dispatch } = useContext(AuthContext)

    const logout = () => {
        googleLogout()
        dispatch({ type: 'logout' })
        navigate('/login')
    }


    const { data, loading } = useQuery(GET_DATA)

    /* Handle Loading State */
    if(loading) {
        return <div></div>
    }

    const MenuItemStyle = styled.li({
        color: '#33334f',
        cursor: 'pointer',
        padding:'5px 10px',
        ':hover': {
            background: 'rgba(83, 51, 237, 0.06)',
            borderRadius: 6,
        },
    })
    const MenuItemStyleActive = styled.li({
        color: '#fff',
        background: '#5333ed',
        cursor: 'pointer',
        padding:'5px 10px',
        borderRadius: 6,
    })

    const handleMenuItemClick = (path) => {
        const start = searchParams.get('start')
        const end = searchParams.get('end')

        if(start && end) {
            navigate(`${path}?start=${start}&end=${end}`)
        } else {
            navigate(path)
        }
    }

    const permissions = data.getCurrentUserFrontendPermissions

    const userReports : any[] = []
    const categoryAccess = permissions.filter(permission => permission.type === 'category').map(permission => permission.permission)
    const pageAccess = permissions.filter(permission => permission.type === 'page').map(permission => permission.permission)
    const isAdmin = permissions.filter(permission => permission.type === 'admin' && permission.permission === 'yes').length > 0

    reports.forEach(category => {
        if(category.visibleToAll === true) {
            userReports.push(category)
            return
        }
        if(
            category.adminOnly === false
            && categoryAccess.includes('all')
            || categoryAccess.includes(category.id)
        ) {
            userReports.push(category)
            return
        }

        if(category.adminOnly && isAdmin) {
            userReports.push(category)
            return
        }

        const categoryItems = category.items.filter(page =>
            user.permissions.filter(permission => permission.type === 'page' && permission.permission === page.id).length
        )
        if(categoryItems.length > 0) {
            userReports.push({
                id: category.id,
                menuTitle: category.menuTitle,
                items: categoryItems

            })
        }

    })
    
    return <div>
        <Layout style={{ minHeight: '100vh', background: '#f5f5f7' }}>

            <Layout style={{ background: "transparent"}}>
                <Sider style={{ background: "transparent", marginLeft: 24}}>
                    <div style={{display:'flex', alignItems: 'center', height:100}}>
                        <img src={user.picture} style={{ width:36, height:36, borderRadius: 48}}/>
                        <div style={{ paddingLeft: 6, lineHeight:1, fontSize: 16}}>
                            <div>Hi {user.name}</div>
                            <div><Button onClick={logout} size='small' type='link' style={{lineHeight:1, height: 'auto',padding:0, fontSize:12}}>Log out</Button>
                            </div>
                        </div>
                    </div>

                    {userReports.map((report, i) => {
                        return <div key={report.id}>
                            <div style={{ marginLeft: 10, fontSize: 10, fontWeight: 600, textTransform:"uppercase", color:"#7171a6", marginBottom:12}}>{report.menuTitle}</div>
                            <ul style={{ listStyleType:'none', padding:0, margin:"0 0 24px 0"}}>
                                {report.items.map(item => {
                                    if(location.pathname === `/${report.id}/${item.id}`) {
                                        return <MenuItemStyleActive onClick={() => handleMenuItemClick(`/${report.id}/${item.id}`)} key={`${report.id}-${item.id}`}>{item.menuTitle}</MenuItemStyleActive>

                                    } else {
                                        return <MenuItemStyle onClick={() => handleMenuItemClick(`/${report.id}/${item.id}`)} key={`${report.id}-${item.id}`}>{item.menuTitle}</MenuItemStyle>

                                    }
                                })}
                            </ul>
                        </div>
                    })}
                </Sider>


                <Content>

                    <Outlet />

                </Content>
            </Layout>
        </Layout>
    </div>
}

export default Main