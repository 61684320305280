import React from "react";
import {Button, Select, Space} from "antd";
import {AreaChartOutlined, BarChartOutlined} from "@ant-design/icons";

const typeSelectorChangeHandler = (value, dimension, filter, setFilter, searchParams, setSearchParams) => {    
    const newDimension = value || filter[dimension.name]

    setFilter({
        ...filter,
        [dimension.name]: value
    })
    setSearchParams({
        ...Object.fromEntries([...searchParams]),
        [dimension.name]: value
    })

}

const handleStackChange = (dimension, stackDimensionState, setStackDimensionState, searchParams, setSearchParams, setFilter) => {
    const currentState = searchParams.get('stackDimension')    
    let newState = ''
    if((currentState && currentState !== dimension.name) || !currentState) {
        newState = dimension.name
    }

    const newParams = {
        ...Object.fromEntries([...searchParams]),
    }

    if(newState) {
        newParams.stackDimension = newState
    }

    if(!newState) {
        delete newParams['stackDimension']
    }
    setStackDimensionState(newState)
    setFilter([])
    setSearchParams(newParams)

}

const renderStackButton = (dimension, stackDimensionState, setStackDimensionState, searchParams, setSearchParams, setFilter) => {    
    let type, icon, text
    if(dimension.name === stackDimensionState) {
        type = 'primary'
        icon = <AreaChartOutlined />
        text = 'Unstack'
    } else {
        type = 'primary'
        icon = <BarChartOutlined />
        text = 'Stack'
    }

    return <Button
        onClick={() => handleStackChange(dimension, stackDimensionState, setStackDimensionState, searchParams, setSearchParams, setFilter)}
        type={type}
    >{icon} {text}</Button>
}

const TypeSelector: React.FC<{ dimension, filter, setFilter, searchParams, setSearchParams, allowStack, stackDimensionState, setStackDimensionState}> = ({ dimension, filter, setFilter, searchParams, setSearchParams, allowStack, stackDimensionState, setStackDimensionState}) => {    
    return <div>
        <Space.Compact>
            <Select
                mode={"multiple"}
                disabled={stackDimensionState !== ''}
                placeholder={"Please select"}
                allowClear={true}
            value={filter[dimension.name]}
            style={{width: 160}}
            onChange={(value) => typeSelectorChangeHandler(value, dimension, filter, setFilter, searchParams, setSearchParams)}
            options={dimension.options}
            />
            {allowStack ? renderStackButton(dimension, stackDimensionState, setStackDimensionState, searchParams, setSearchParams, setFilter) : null}
        </Space.Compact>
    </div>
}

export default TypeSelector
