import React from "react";
import {Collapse, Typography} from "antd";
import DocsAccountHistory from "./DocsAccountHistory";
import DocsPrices from "./DocsPrices";
import DocsAccountActivity from "./DocsAccountActivity";
import {
    ArrowRightOutlined, BookTwoTone,
    CalculatorTwoTone,
    DatabaseTwoTone,
    InfoCircleTwoTone
} from "@ant-design/icons";
import { BlockMath } from 'react-katex';

const DocsNewSubscriptions: React.FC = (props) => {
    const { Text, Paragraph, Title } = Typography;


    return <div>

        <Paragraph>
            <Title level={5} style={{marginTop:0}}>
                <CalculatorTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Formula
            </Title>
        </Paragraph>

        <BlockMath math="\large
        \sum_{} \text{New Subscriptions} "/>


        <Paragraph>
            <Title level={5}>
                <DatabaseTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Data Source
            </Title>
        </Paragraph>

        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="newSubscriptions" key="dependency">
                <div style={{display: 'flex', alignItems:'center', gap: 60}}>
                    <div>
                        <ul>
                            <li>Stripe Events
                                <ul>
                                    <li><Text code>accountHistory</Text></li>
                                    <li><Text code>prices</Text></li>
                                    <li><Text code>customers</Text></li>
                                    <li><Text code>customerCountry</Text></li>
                                </ul>
                            </li>
                            <li>
                                Stripe Sigma
                                <ul>
                                    <li><Text code>usageRecords</Text></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ArrowRightOutlined style={{ fontSize: 30}} />
                    </div>
                    <div>
                        <Text code>accountActivity</Text>
                    </div>

                    <div>
                        <ArrowRightOutlined style={{ fontSize: 30}} />
                    </div>
                    <div>
                        <Text code>newSubscriptions</Text>
                    </div>

                </div>
            </Collapse.Panel>
        </Collapse>

        <Paragraph>
            <Title level={5}>
                <InfoCircleTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Details
            </Title>
        </Paragraph>

        <Paragraph>
            To arrive at the number of new subscriptions we sum all items in the account activity table for the given
            day where the type is <Text code>new</Text> or <Text code>expansion</Text>. Note that the number of new
            subscriptions should be much higher than new customers but the shape of the graph should be relatively close.
        </Paragraph>



        <Paragraph>
            <Title level={5}>
                <BookTwoTone twoToneColor={'#5333ed'} style={{ marginRight: 10}} />
                Further Reading
            </Title>
        </Paragraph>


        <Collapse style={{marginBottom :20 }}>
            <Collapse.Panel header="Understanding Account History" key="account-history">
                <DocsAccountHistory />
            </Collapse.Panel>

            <Collapse.Panel header="Understanding Prices" key="prices">
                <DocsPrices />
            </Collapse.Panel>

            <Collapse.Panel header="Understanding Account Activity" key="account-activity">
                <DocsAccountActivity />
            </Collapse.Panel>


        </Collapse>



    </div>

}

export default DocsNewSubscriptions